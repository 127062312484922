import { IconButton } from "@suraasa/placebo-ui"
import { CropRotateTr } from "iconoir-react"
import { useEffect, useRef, useState } from "react"

import { addZoomPan } from "./preview"

type Props = {
  url: string
}

const ImageViewer = ({ url }: Props) => {
  const ref = useRef<HTMLImageElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [rotate, setRotate] = useState(0)

  // const onDownload = async () => {
  //   saveURLAsFile({ url })
  // }

  const onRotate = () => {
    const image = ref.current

    if (!image) return

    const newRotation = (() => {
      if (rotate >= 360) {
        return 90
      }
      return rotate + 90
    })()
    setRotate(newRotation)

    image.style.transform = `rotate(${newRotation}deg)`
    image.style.transformOrigin = "center"
  }

  useEffect(() => {
    const image = ref.current
    const container = containerRef.current
    if (image && container) {
      addZoomPan({ container, image })
    }
  }, [])

  return (
    <>
      <div
        ref={containerRef}
        className="flex h-[100dvh] w-screen snap-x snap-mandatory"
      >
        <div
          id="container"
          className="[100dvh] flex w-[100dvw] shrink-0 cursor-zoom-in touch-manipulation select-none snap-start items-center justify-center"
        >
          <img
            src={url}
            alt=""
            className="pointer-events-none max-h-full max-w-full cursor-zoom-in will-change-transform"
            ref={ref}
          />
        </div>
      </div>

      <div className="absolute bottom-2 m-auto w-full">
        <div className="mx-auto max-w-[300px] rounded-xl bg-onSurface-800 px-2 py-1 text-white">
          <div className="flex items-center justify-between gap-1.25 divide-x">
            <div>Scroll/Pinch to zoom</div>
            <div className="ps-1">
              <IconButton color="white" onClick={onRotate}>
                <CropRotateTr />
              </IconButton>
            </div>
            {/* <div>
              <IconButton color="white" size="xs" onClick={() => onDownload()}>
                <Download />
              </IconButton>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )

  // return (
  //   <div className="relative flex size-full flex-col items-center justify-center">
  //     <div
  //       // className="relative mb-10 min-w-[80vw] max-w-2xl md:min-w-[unset] xl:max-w-7xl"
  //       style={{
  //         height: dimensions.height || "100%",
  //         width: dimensions.width || "100%",
  //       }}
  //     >
  //       <div className="inline-block w-full" ref={containerRef}>
  //         <img
  //           src={url}
  //           alt=""
  //           className="relative"
  //           style={{
  //             transform: `scale(${zoom}%)`,
  //             transformOrigin: "center",
  //             // overflow: zoom > 100 ? "scroll" : "unset",
  //             // overflowX: zoom > 100 ? "scroll" : "unset",
  //             rotate: `${rotate}deg`,
  //           }}
  //           onMouseMove={e => {
  //             if (zoom > 100) {
  //               onMove(e)
  //             }
  //           }}
  //           ref={ref}
  //         />
  //       </div>
  //     </div>
  //     <div className="mt-5 w-full">
  //       <div className="flex w-full items-center justify-center gap-1.25 rounded-xl bg-onSurface-800 p-1">
  //         <div>
  //           <IconButton color="white" size="xs" onClick={() => zoomOut()}>
  //             <Minus fontSize={10} />
  //           </IconButton>
  //         </div>
  //         <div>
  //           <p className="bg-onSurface-800 text-white">{zoom}%</p>
  //         </div>
  //         <div>
  //           <IconButton color="white" size="xs" onClick={() => zoomIn()}>
  //             <Plus />
  //           </IconButton>
  //         </div>
  //         <div>
  //           <IconButton
  //             color="white"
  //             size="xs"
  //             className="mb-0.5"
  //             onClick={() => {
  //               setRotate(turn => {
  //                 if (turn === 360) {
  //                   return 0
  //                 }
  //                 return turn + 90
  //               })
  //             }}
  //           >
  //             <CropRotateTr />
  //           </IconButton>
  //         </div>
  //         <div>
  //           <IconButton color="white" size="xs" onClick={() => onDownload()}>
  //             <Download />
  //           </IconButton>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default ImageViewer
