import { IconButton, Typography } from "@suraasa/placebo-ui"
import { Users } from "api/resources/applications/types"
import clsx from "clsx"
import { format } from "date-fns"
import { ArrowRight, Check } from "iconoir-react"
import React from "react"
import { Link } from "react-router-dom"

import ApplicationTag, { getTagDetails } from "../Tools/ApplicationTag"

const ApplicationRow = ({ data }: { data: Users }) => {
  return (
    <div
      key={data.id.toString()}
      className={clsx(
        "grid w-full grid-cols-1 items-center gap-2 border-b border-solid border-b-onSurface-200 bg-white p-1.5 text-start md:grid-cols-12 md:gap-0"
      )}
    >
      <div className="col-span-full flex items-start md:relative md:col-span-4 md:block">
        <Typography variant="strong" className="block md:hidden">
          Name:
        </Typography>
        <div className="ml-1">
          <Typography variant="strong">
            {data.applicationDetail?.firstName}{" "}
            {data.applicationDetail?.lastName}
          </Typography>
          <Typography variant="smallBody">
            {data.applicationDetail?.email}
          </Typography>
        </div>
      </div>
      <div className="col-span-full flex items-start gap-1 text-start md:col-span-2 md:block">
        <Typography variant="strong" className="block md:hidden">
          Date:
        </Typography>{" "}
        <Typography variant="body">
          {data.submitTime
            ? format(new Date(data.submitTime), "d MMMM yyyy")
            : `-`}
        </Typography>
      </div>
      <div className="col-span-full flex items-center gap-1 md:col-span-2 md:block">
        <Typography variant="strong" className="block md:hidden">
          Status:
        </Typography>{" "}
        <ApplicationTag
          label={getTagDetails({
            applicationStatus: data.status,
            interviewStatus: data.interview?.status,
            reviewStatus: data.review?.status,
            eligibleForInterview: data.eligibleForInterview,
          })}
        />
      </div>
      <div className="col-span-full flex items-center gap-1 text-start md:col-span-1 md:block">
        <Typography variant="strong" className="block md:hidden">
          Documents:
        </Typography>{" "}
        {data.evidenceUploaded && (
          <div className="grid size-[16.67px] place-items-center rounded-full bg-success-600 text-white">
            <Check className="size-1.5" strokeWidth={3} />
          </div>
        )}
      </div>
      <div className="col-span-full ml-auto flex w-full items-start gap-1 md:col-span-2 md:justify-start">
        <Typography variant="strong" className="block md:hidden">
          Assigned To:
        </Typography>{" "}
        <Typography>
          {data.review && data.review.reviewer.fullName
            ? data.review?.reviewer.fullName
            : "-"}
        </Typography>
      </div>
      <div className="col-span-full ml-auto flex w-full items-center gap-1 md:col-span-1 md:justify-center">
        <Link to={`/dashboard/view/${data.id}`}>
          <IconButton variant="filled" rounded className="!size-[20px]">
            <ArrowRight className="!size-1.5" strokeWidth={2} />
          </IconButton>
        </Link>
      </div>
    </div>
  )
}

export default ApplicationRow
