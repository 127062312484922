import { BadgeProps, cn, Tag } from "@suraasa/placebo-ui"
import {
  ApplicationStatus,
  InterviewStatus,
  ReviewStatus,
} from "api/resources/applications/types"

export type CustomTagsColors =
  | NonNullable<BadgeProps["color"]>
  | "inverted_primary"
  | "inverted_success"
  | null
  | undefined

export enum TagLabels {
  reviewPending = "Review Pending",
  rejected = "Rejected",
  abandoned = "Abandoned",
  admissionGranted = "Admission Granted",
  inReview = "In Review",
  changesRequested = "Changes Requested",
  changesDone = "Changes Done",
  applicationAccepted = "Application Accepted",
  interviewPending = "Interview Pending",
  interviewScheduled = "Interview Scheduled",
  interviewDone = "Interview Done",
}

export const getTagDetails = ({
  applicationStatus,
  reviewStatus,
  interviewStatus,
  eligibleForInterview,
}: {
  applicationStatus?: ApplicationStatus | null
  reviewStatus?: ReviewStatus | null
  interviewStatus?: InterviewStatus | null
  eligibleForInterview?: boolean
}): TagLabels | string => {
  switch (applicationStatus) {
    case ApplicationStatus.SUBMITTED:
      return TagLabels.reviewPending
    case ApplicationStatus.REJECTED:
      return TagLabels.rejected
    case ApplicationStatus.ABANDONED:
      return TagLabels.abandoned
    case ApplicationStatus.ACCEPTED:
      return TagLabels.admissionGranted
  }

  switch (reviewStatus) {
    case ReviewStatus.REVIEW_STARTED:
      return TagLabels.inReview
    case ReviewStatus.CHANGES_STARTED:
    case ReviewStatus.CHANGES_REQUESTED:
      return TagLabels.changesRequested
    case ReviewStatus.CHANGES_DONE:
      return TagLabels.changesDone
  }

  if (
    reviewStatus === ReviewStatus.ACCEPTED &&
    eligibleForInterview === false
  ) {
    return TagLabels.applicationAccepted
  }

  if (
    reviewStatus === ReviewStatus.ACCEPTED &&
    eligibleForInterview &&
    (!interviewStatus || interviewStatus === InterviewStatus.CANCELLED)
  ) {
    return TagLabels.interviewPending
  }
  if (
    reviewStatus === ReviewStatus.ACCEPTED &&
    eligibleForInterview &&
    interviewStatus === InterviewStatus.SCHEDULED
  ) {
    return TagLabels.interviewScheduled
  }

  if (
    reviewStatus === ReviewStatus.ACCEPTED &&
    eligibleForInterview &&
    interviewStatus &&
    [
      InterviewStatus.ACCEPTED,
      InterviewStatus.REJECTED,
      InterviewStatus.NO_SHOW,
    ].includes(interviewStatus)
  ) {
    return TagLabels.interviewDone
  }

  return JSON.stringify(
    {
      applicationStatus,
      reviewStatus,
      interviewStatus,
      eligibleForInterview,
    },
    null,
    2
  )
}

const ApplicationTag = ({
  label,
  className,
}: {
  label: TagLabels | string
  className?: string
}) => {
  switch (label) {
    case TagLabels.reviewPending:
      return (
        <Tag className={className} color="warning">
          {TagLabels.reviewPending}
        </Tag>
      )
    case TagLabels.inReview:
      return (
        <Tag className={cn("bg-primary-500 !text-primary-50", className)}>
          {TagLabels.inReview}
        </Tag>
      )
    case TagLabels.changesRequested:
      return (
        <Tag className={className} color="onSurface">
          {TagLabels.changesRequested}
        </Tag>
      )
    case TagLabels.changesDone:
      return (
        <Tag className={className} color="warning">
          {TagLabels.changesDone}
        </Tag>
      )
    case TagLabels.interviewPending:
      return (
        <Tag className={className} color="onSurface">
          {TagLabels.interviewPending}
        </Tag>
      )
    case TagLabels.interviewScheduled:
      return (
        <Tag className={className} color="primary">
          {TagLabels.interviewScheduled}
        </Tag>
      )
    case TagLabels.interviewDone:
      return (
        <Tag className={className} color="warning">
          {TagLabels.interviewDone}
        </Tag>
      )
    case TagLabels.abandoned:
      return (
        <Tag className={className} color="critical">
          {TagLabels.abandoned}
        </Tag>
      )
    case TagLabels.admissionGranted:
      return (
        <Tag className={cn("bg-success-500 !text-success-50", className)}>
          {TagLabels.admissionGranted}
        </Tag>
      )
    case TagLabels.applicationAccepted:
      return (
        <Tag className={className} color="success">
          {TagLabels.applicationAccepted}
        </Tag>
      )

    case TagLabels.rejected:
      return (
        <Tag className={className} color="critical">
          {TagLabels.rejected}
        </Tag>
      )

    default:
      return (
        <Tag className={cn("bg-black/70 text-onSurface-50", className)}>
          {label}
        </Tag>
      )
  }
}

export default ApplicationTag
