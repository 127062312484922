import { useContext } from "react"
import { Outlet } from "react-router-dom"
import { ActionEnum } from "utils/action"

import { GlobalContext } from "./GlobalState"
import ErrorPage from "./shared/ErrorPage"

const AdminRoute = () => {
  const { actions } = useContext(GlobalContext)

  return actions.includes(ActionEnum.viewAllSubmittedApplications) ? (
    <>
      <Outlet />
    </>
  ) : (
    <ErrorPage errorCode={403} />
  )
}

export default AdminRoute
