import axios from "api/axios"
import {
  APIResponse,
  APIResponseOld,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatErrorResponseOld,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import {
  ApplicantDetails,
  CommentType,
  Overview,
  ProfileDataType,
  Reviewer,
  ReviewStatusType,
  Users,
} from "./types"
import { urls } from "./urls"

export default {
  applications: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<Users[]>> => {
    try {
      const res = await axios.get(urls.applications.list(), { params })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieve: async ({
    urlParams: { applicationId },
    params,
  }: ConfigWithURLParams): Promise<APIResponse<ApplicantDetails>> => {
    try {
      const res = await axios.get(urls.applications.retrieve(applicationId), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieveProfile: async ({
    urlParams: { applicationId },
    params,
  }: ConfigWithURLParams): Promise<APIResponse<ProfileDataType>> => {
    try {
      const res = await axios.get(
        urls.applications.retrieveProfile(applicationId),
        {
          params,
        }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateApplication: async ({
    urlParams: { applicationId },
    data,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(
        urls.applications.updateApplication(applicationId),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  review: {
    reviewersList: async (): Promise<APIResponse<Reviewer[]>> => {
      try {
        const res = await axios.get(urls.review.reviewersList())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponse<CommentType[]>> => {
      try {
        const res = await axios.get(urls.review.list(), { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    bulkCreate: async ({
      urlParams: { reviewId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<CommentType[]>> => {
      try {
        const res = await axios.post(urls.review.bulkCreate(reviewId), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    bulkCreateDeclaration: async ({
      urlParams: { applicationId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.post(
          urls.applications.bulkCreateDeclaration(applicationId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    create: async ({
      urlParams: { applicationId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<ReviewStatusType>> => {
      try {
        const res = await axios.post(urls.review.create(applicationId), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    cancelChanges: async ({
      urlParams: { reviewId },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.review.cancelChanges(reviewId))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateStatus: async ({
      params,
      data,
    }: Config): Promise<APIResponse<ReviewStatusType>> => {
      try {
        const res = await axios.put(urls.review.update(), data, { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    // updateStatus: async ({
    //   params,
    //   data,
    // }: Config): Promise<APIResponse<NoContentType>> => {
    //   try {
    //     const res = await axios.put(urls.review.update(), data, {
    //       params,
    //     })
    //     return formatSuccessResponse(res)
    //   } catch (e) {
    //     return formatErrorResponse(e)
    //   }
    // },
  },
  interview: {
    update: async ({
      params,
      data,
    }: Config): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.put(urls.interview.update(), data, {
          params,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
    cancelInterview: async ({
      urlParams: { interviewId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.put(
          urls.interview.cancelInterview(interviewId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  overview: async (): Promise<APIResponse<Overview>> => {
    try {
      const res = await axios.get(urls.overview.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
