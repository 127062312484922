import { Typography } from "@suraasa/placebo-ui"
import Card from "components/shared/Card"
import { useContext } from "react"
import { ApplicationContext } from "views/application/context/ApplicationContext"

const DeclarationCard = () => {
  const { applicantData } = useContext(ApplicationContext)

  const name = [applicantData.firstName, applicantData.lastName]
    .filter(Boolean)
    .join(" ")

  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Declaration</Typography>
      <div>
        <Typography>
          I, <strong>{name},</strong> declare that I am responsible for doing my
          research on the requirements to achieve my desired outcomes, and that
          I will not hold Suraasa or any of its related organisations
          responsible for the same.
          <br />
          <br />
          I give permission to Suraasa to share information or request
          information on my behalf for purpose of assessment, review and
          verification, and contact my references for verification or background
          check. I also consent to receiving text/call communication from
          Suraasa for the purpose of my admission. I understand that this
          consent overrides the DNC Registry and NCPR.
          <br />
          <br />
          By submitting this application, I hereby agree to the Suraasa&apos;s{" "}
          <a
            className="inline underline"
            href="https://suraasa.com/terms-of-use"
            rel="noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>
          ,{" "}
          <a
            className="inline underline"
            href="https://www.suraasa.com/privacy-policy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            className="underline"
            href="https://www.suraasa.com/eula"
            rel="noreferrer"
            target="_blank"
          >
            EULA
          </a>{" "}
          (End User License Agreement).
        </Typography>
      </div>
    </Card>
  )
}

export default DeclarationCard
