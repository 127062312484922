import { BadgeProps, toast } from "@suraasa/placebo-ui"
import {
  ApplicationStatus,
  Evidence,
  EvidenceType,
  InterviewStatus,
  ReviewStatus,
} from "api/resources/applications/types"
import { APIError } from "api/utils"
import { differenceInYears, format, parse } from "date-fns"
import { FieldValues, UseFormSetError } from "react-hook-form"

export function isUUIDString(str: string) {
  const UUIDv4Regex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  return UUIDv4Regex.test(str)
}

export function narrowError(e: unknown): e is APIError {
  return e instanceof APIError
}

/**
 * @param setter setter to set errors in the form
 * @param fieldErrors errors from the API
 * @param fields Format: [BackendKey, FrontendKey] OR [key] (if both key names are same)
 */
export const mapErrors = <T extends FieldValues>(
  err: any,
  options?: {
    setter: UseFormSetError<T>
    fields: ([string, string] | [string])[]
  }
) => {
  if (!narrowError(err)) {
    throw new Error(
      "Error supplied to mapErrors is not an instance of APIError. Throwing original error",
      err
    )
  }

  const {
    errors: { fieldErrors, message },
  } = err

  if (message) {
    toast.error(message)
  }

  // if there was no message, but there were fieldErrors without a setter, we need to show them to the user
  if (!message && fieldErrors && !options) {
    const firstError = Object.entries(fieldErrors)[0]
    if (firstError) {
      const [k, v] = firstError
      toast.error(`Error on key '${k}'`, {
        description: typeof v === "string" ? v : JSON.stringify(v[0]),
      })
    }
  }

  options?.fields.forEach(pair => {
    if (!fieldErrors) return

    const key = pair[0]
    const message = Array.isArray(fieldErrors[key])
      ? fieldErrors[key].join("; ")
      : fieldErrors[key]

    if (pair.length === 1) {
      if (message) {
        options.setter(
          key as any,
          {
            message: message as string,
          },
          { shouldFocus: true }
        )
      }
    }
    if (pair.length === 2) {
      const frontendKey = pair[1]
      if (message) {
        options.setter(
          frontendKey as any,
          { message: message as string },
          { shouldFocus: true }
        )
      }
    }
  })
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export const saveBlobAsFile = ({
  data,
  type,
  name,
}: {
  data: any
  type: string
  name: string
}) => {
  const blob = new Blob([data], { type })
  const blobData = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = blobData
  link.download = name
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(blobData)
  }, 100)
}

export const saveURLAsFile = ({ url }: { url: any }) => {
  const link = document.createElement("a")
  link.href = url
  link.target = "_blank"
  link.click()
  setTimeout(() => {
    link.remove()
  }, 100)
}

export const getPlatformURL = (platform: "sso", url: string) => {
  switch (platform) {
    case "sso":
      return `${import.meta.env.VITE_SSO_URL}/${url}`
    default:
      return url
  }
}

export function checkEnvVars(vars: string[]) {
  for (const v of vars) {
    if (!import.meta.env[v]) {
      throw new Error(`Please add ${v} to your environment variables`)
    }
  }
}

export const getEvidenceLink = (evidence: Evidence) => {
  if (evidence.evidenceType === EvidenceType.File) return evidence.file

  return evidence.url
}

export const formatDate = (date: string | undefined, formatStr = "LLL yyyy") =>
  date ? format(new Date(date), formatStr) : ""

export const getApplicationTag = ({
  applicationStatus,
  interviewStatus,
  reviewStatus,
}: {
  applicationStatus?: ApplicationStatus
  interviewStatus?: InterviewStatus | null
  reviewStatus?: ReviewStatus
}): { color: BadgeProps["color"]; text: string } | null => {
  if (interviewStatus) {
    switch (interviewStatus) {
      case InterviewStatus.ACCEPTED:
        return { color: "success", text: "Accepted via Interview" }
      case InterviewStatus.REJECTED:
        return { color: "critical", text: "Rejected via Interview" }
      case InterviewStatus.CANCELLED:
        return { color: "critical", text: "Interview Cancelled" }
      case InterviewStatus.SCHEDULED:
        return { color: "warning", text: "Interview Scheduled" }
      case InterviewStatus.NO_SHOW:
        return { color: "critical", text: "Skipped Interview" }
      default:
        return null
    }
  }
  if (reviewStatus) {
    switch (reviewStatus) {
      case ReviewStatus.CHANGES_REQUESTED:
        return { color: "warning", text: "Changes Requested" }
      case ReviewStatus.CHANGES_STARTED:
        return { color: "warning", text: "Changes Requested" }
      case ReviewStatus.CHANGES_DONE:
        return { color: "warning", text: "Changes Done" }
      case ReviewStatus.REVIEW_STARTED:
        return { color: "primary", text: "Review Application" }
      // case ReviewStatus.INTERVIEW_REQUESTED:
      //   return { color: "onSurface", text: "Interview Pending" }
      case ReviewStatus.ACCEPTED:
        return { color: "success", text: "Accepted" }
      case ReviewStatus.REJECTED:
        return { color: "critical", text: "Rejected" }
      default:
        return null
    }
  }

  if (applicationStatus && applicationStatus === ApplicationStatus.REJECTED) {
    return { color: "critical", text: "Rejected" }
  }
  if (applicationStatus && applicationStatus === ApplicationStatus.ACCEPTED) {
    return { color: "success", text: "Accepted" }
  }
  return null
}

export const calculateAge = (dob: string): number => {
  const date = parse(dob, "yyyy-MM-dd", new Date())
  const age = differenceInYears(new Date(), date)
  return age
}
