import { Typography } from "@suraasa/placebo-ui"
import Card from "components/shared/Card"
import React, { useContext } from "react"
import { ApplicationContext } from "views/application/context/ApplicationContext"

const ReferencesCard = () => {
  const { references } = useContext(ApplicationContext)

  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">References</Typography>
      <div className="flex flex-col gap-2 pl-8">
        {references.length === 0 && (
          <Typography className="mb-2 text-center" variant="strong">
            No Data Available
          </Typography>
        )}
        {references.map(item => (
          <div className="flex flex-col gap-x-3 sm:flex-row" key={item.id}>
            <Typography variant="strong">{item.name}</Typography>
            <Typography variant="body">{item.email}</Typography>
            {item.phoneNumber.countryCode && item.phoneNumber.number && (
              <Typography color="onSurface.500" variant="body">
                +{item.phoneNumber.countryCode} {item.phoneNumber.number}
              </Typography>
            )}
          </div>
        ))}
      </div>
    </Card>
  )
}

export default ReferencesCard
