import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"

const AcceptApplicationDialog = ({
  open,
  onClose,
  name,
  onClick,
  loading,
}: {
  open: boolean
  onClose: () => void
  name: string
  onClick: () => void
  loading?: boolean
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="bg-white" aria-describedby="dialog-description">
        <DialogHeader>
          <DialogTitle>Accept Application</DialogTitle>
        </DialogHeader>
        <DialogBody className="rounded-3xl">
          <Typography className="pb-3">
            Are you sure you want to accept <strong>{name}’s</strong>{" "}
            application?
          </Typography>
        </DialogBody>
        <DialogFooter>
          <Button loading={loading} size="sm" onClick={onClick}>
            Accept
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AcceptApplicationDialog
