import { CircularProgress, Container } from "@suraasa/placebo-ui"
import DeclarationCard from "components/application/DeclarationCard"
import DisabilityFitnessCard from "components/application/DisabilityFitnessCard"
import EducationCard from "components/application/EducationCard"
import EmploymentCard from "components/application/EmploymentCard"
import FloatingBar from "components/application/FloatingBar"
import LanguageCard from "components/application/LanguageCard"
import OthersCard from "components/application/OthersCard"
import PersonalDetails from "components/application/PersonalDetails"
import ReferencesCard from "components/application/ReferencesCard"
import ReviewerDeclarationCard from "components/application/ReviewerDeclarationCard"
import TitleBar from "components/application/TitleBar"
import Navbar from "components/shared/Navbar/Navbar"
import { useContext } from "react"
import { useParams } from "react-router-dom"

import {
  ApplicationContext,
  ApplicationContextProvider,
} from "./context/ApplicationContext"

const Wrapper = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  const { isLoading, isFetching } = useContext(ApplicationContext)

  return (
    <Container className={className}>
      {isLoading ? (
        <div className="grid place-items-center p-4">
          <CircularProgress />
        </div>
      ) : (
        <div className="relative">
          {isFetching && (
            <div className="absolute left-0 top-0 z-[2] grid size-full items-start justify-center bg-white/75 pt-10">
              <CircularProgress />
            </div>
          )}
          {children}
        </div>
      )}
    </Container>
  )
}
const Review = () => {
  const { applicationId } = useParams()
  if (!applicationId) return null

  return (
    <ApplicationContextProvider applicationId={applicationId}>
      <>
        <Navbar gutterBottom={false} />

        <TitleBar />
        <Wrapper className="mb-20 mt-3 placebo-container-w-full sm:placebo-container">
          <PersonalDetails />
          <EmploymentCard />
          <LanguageCard />
          <EducationCard />
          <DisabilityFitnessCard />
          <ReferencesCard />
          <OthersCard />
          <DeclarationCard />
          <ReviewerDeclarationCard />
          <FloatingBar />
        </Wrapper>
      </>
    </ApplicationContextProvider>
  )
}

export default Review
