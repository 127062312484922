import { Button, IconButton, Typography } from "@suraasa/placebo-ui"
import Card from "components/shared/Card"
import { Plus, Xmark } from "iconoir-react"
import * as localForage from "localforage"
import { useContext } from "react"
import { ApplicationContext } from "views/application/context/ApplicationContext"

import AcademicEvidence from "../AcademicEvidence"
import DeclarationForm from "./DeclarationForm"

const ReviewerDeclarationCard = () => {
  const {
    applicationId,
    isReviewDisabled,
    setLocalDeclarations,
    localDeclarations,
    declarations,
    canTakeActions,
  } = useContext(ApplicationContext)

  // useEffect(() => {
  //   localForage
  //     .getItem<CheckListType>(applicationId)
  //     .then(item => {
  //       if (item && item.applicationDeclaration) {
  //         setApplicationDeclarations(item.applicationDeclaration)
  //       }
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   if (!isReviewDisabled) {
  //     if (applicationDeclaration.length > 0) {
  //       console.log("setting applicationDeclaration", applicationDeclaration)
  //       localForage
  //         .getItem<CheckListType>(applicationId)
  //         .then(item => {
  //           localForage.setItem(applicationId, {
  //             ...item,
  //             applicationDeclaration: applicationDeclaration,
  //           })
  //         })
  //         .catch(err => {
  //           console.error(err)
  //         })
  //     } else {
  //       localForage
  //         .getItem<CheckListType>(applicationId)
  //         .then(item => {
  //           if (item && item.applicationDeclaration) {
  //             delete item.applicationDeclaration
  //             localForage.setItem(applicationId, item)
  //           }
  //         })
  //         .catch(err => {
  //           console.error(err)
  //         })
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [applicationDeclaration, isReviewDisabled])
  const onClickAddDeclaration = () => {
    setLocalDeclarations(prev => [
      ...prev,
      { id: prev.length + 1, declaration: "" },
    ])
  }
  const onDeleteDeclaration = (id: number) => {
    setLocalDeclarations(prev => prev.filter(i => i.id !== id))
  }

  return (
    <Card className="mt-4 p-2">
      <div className="flex flex-wrap items-center justify-between gap-1">
        <Typography variant="title3">Declaration</Typography>
        {!isReviewDisabled && canTakeActions && (
          <Button
            onClick={onClickAddDeclaration}
            startAdornment={<Plus />}
            variant="text"
          >
            Add New Declaration
          </Button>
        )}
      </div>
      {/* {localDeclarations.length === 0 &&
        !isReviewDisabled &&
        declarations.length === 0 &&
        canTakeActions && (
          <Button
            onClick={onClickAddDeclaration}
            startAdornment={<Plus />}
            variant="text"
          >
            Click to add new declaration
          </Button>
        )} */}
      {[...declarations, ...localDeclarations]
        .filter(i => i.declaration)
        .map((item, index) => (
          <div
            key={item.id}
            className="relative rounded-2xl border border-onSurface-300 p-2"
          >
            {!isReviewDisabled &&
              canTakeActions &&
              localDeclarations.some(i => i.id === item.id) && (
                <IconButton
                  variant="plain"
                  color="black"
                  className="absolute right-1 top-1"
                  onClick={() => {
                    onDeleteDeclaration(item.id)
                  }}
                >
                  <Xmark />
                </IconButton>
              )}
            <Typography className="mb-1" variant="title4">
              Declaration {index + 1}
            </Typography>
            <Typography
              className="whitespace-break-spaces rounded-2xl border border-onSurface-300 bg-onSurface-50 px-2 py-1 text-onSurface-800"
              variant="smallBody"
            >
              {item.declaration}
            </Typography>
            {item.requiredDocuments && (
              <>
                <Typography variant="strong" className="mb-1 mt-2">
                  Add Supporting Documents
                </Typography>
                {item.requiredDocuments && (
                  <Typography
                    className="whitespace-break-spaces text-onSurface-800"
                    variant="smallBody"
                  >
                    {item.requiredDocuments}
                  </Typography>
                )}
                {item.applicationDeclarationAttachment &&
                  item.applicationDeclarationAttachment.length > 0 && (
                    <div className="mb-2 mt-1 flex gap-2 overflow-auto">
                      {item.applicationDeclarationAttachment.map((i, index) => (
                        <AcademicEvidence
                          name={`Attachment ${index + 1}`}
                          url={i.attachment}
                          key={index}
                        />
                      ))}
                    </div>
                  )}
              </>
            )}
          </div>
        ))}
      {localDeclarations.length > 0 &&
        localDeclarations
          .filter(i => !i.declaration)

          .map(item => {
            return <DeclarationForm id={item.id} key={item.id} />
          })}
    </Card>
  )
}

export default ReviewerDeclarationCard
