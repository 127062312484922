import { Container } from "@suraasa/placebo-ui"
import ApplicantBar from "components/med/ApplicantBar"
import EducationCard from "components/med/EducationCard"
import EmploymentDetails from "components/med/EmploymentDetails"
import LanguageCard from "components/med/LanguageCard"
import OthersCard from "components/med/OtherInformation"
import PersonalDetails from "components/med/PersonalDetails"
import ProfessionalFitnessDetails from "components/med/ProfessionalFitnessDetails"
import ScholarshipDetails from "components/med/ScholarshipDetails"
import TitleBar from "components/med/TitleBar"
import LoadingOverlay from "components/shared/LoadingOverlay"
import Navbar from "components/shared/Navbar/Navbar"
import { useState } from "react"
import { useParams } from "react-router"

import { MedApplicationContextProvider } from "./context/MEdContext"

const MEdReview = () => {
  const { applicationId } = useParams()
  const [pageLoading, setPageLoading] = useState(false)

  // const isSalesPerson = !actions.includes(ACTIONS.assignApplication)
  // Note: Med applications are not up for review so yea hide checkbox by default.
  const isSalesPerson = true

  const togglePageLoading = (state: boolean) => {
    setPageLoading(state)
  }

  if (!applicationId) return null
  return (
    <MedApplicationContextProvider
      applicationId={applicationId}
      isSalesPerson={isSalesPerson}
      toggleLoading={togglePageLoading}
    >
      <>
        <Navbar gutterBottom={false} />
        <TitleBar />

        {pageLoading && <LoadingOverlay />}
        {!pageLoading && <ApplicantBar />}
        {!pageLoading && (
          <Container className="mb-20 mt-3">
            <PersonalDetails />
            <EmploymentDetails />
            <LanguageCard />
            <EducationCard />
            <OthersCard />
            <ScholarshipDetails />
            <ProfessionalFitnessDetails />
          </Container>
        )}
      </>
    </MedApplicationContextProvider>
  )
}

export default MEdReview
