import { Tag, Typography } from "@suraasa/placebo-ui"
import { WorkExperience } from "api/resources/applications/types"
import Card from "components/shared/Card"
import React, { useContext } from "react"
import { employmentTypeOptions } from "utils/constants"
import { formatDate } from "utils/helpers"
import { MedApplicationContext } from "views/application/masters-in-education/context/MEdContext"

const EmploymentDetails = () => {
  const { workExperiences } = useContext(MedApplicationContext)

  const getSelectedEmploymentType = (data: WorkExperience) => {
    const empType = employmentTypeOptions.filter(
      a => a.value === data.employmentType && a
    )[0]

    return empType.label || ""
  }
  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Employment Information</Typography>
      <div className="flex flex-col gap-2">
        {workExperiences.length === 0 && (
          <Typography className="mb-2 text-center" variant="strong">
            No Data Available
          </Typography>
        )}
        {workExperiences.map(item => (
          <div key={item.id}>
            <div className="flex">
              <Typography variant="strong">{item.title}</Typography>
              {item.suraasaId && (
                <Tag className="pl-1" color="onSurface" size="sm">
                  From Profile
                </Tag>
              )}
            </div>
            <Typography variant="body">
              {item.organisationName}
              <span className="mx-1">&#8226;</span>
              {getSelectedEmploymentType(item)}
            </Typography>
            <Typography color="onSurface.500" variant="smallBody">
              {item.state?.name}, {item.country?.name}
            </Typography>
            <Typography color="onSurface.500" variant="smallBody">
              {formatDate(item.startDate, "MMMM yyyy")}
              <span className="mx-1">-</span>
              {item.endDate ? formatDate(item.endDate, "MMMM yyyy") : "Present"}
            </Typography>
          </div>
        ))}
      </div>
    </Card>
  )
}

export default EmploymentDetails
