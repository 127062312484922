import { BadgeProps } from "@suraasa/placebo-ui"
import {
  ApplicationStatus,
  InterviewStatus,
  Option,
  Response,
  ReviewStatus,
} from "api/resources/applications/types"

export const ethnicityOptions = {
  asianBlack: "Asian Black",
  africanAmerican: "African American",
  alaskaNative: "Alaska Native",
  nativeHawaiian: "Native Hawaiian",
  pacificIslander: "Pacific Islander",
  whiteAmericanIndian: "White American Indian",
  preferNotToDisclose: "Prefer Not to Disclose",
  other: "Other",
}
export enum LearnFromType {
  ADVERTISEMENT = 1,
  SEARCH_ENGINE,
  PROMOTIONAL_EMAIL,
  FACEBOOK,
  LINKEDIN,
  FROM_MY_SCHOOL,
  RECOMMENDATION_FROM_A_COLLEAGUE_OR_FRIEND,
  OTHER,
}
export const learnFromOptions = [
  {
    label: "Advertisement",
    value: LearnFromType.ADVERTISEMENT,
  },
  {
    label: "Search Engine",
    value: LearnFromType.SEARCH_ENGINE,
  },
  {
    label: "Promotional Email",
    value: LearnFromType.PROMOTIONAL_EMAIL,
  },
  { label: "Facebook", value: LearnFromType.FACEBOOK },
  { label: "LinkedIn", value: LearnFromType.LINKEDIN },
  {
    label: "From My School",
    value: LearnFromType.FROM_MY_SCHOOL,
  },
  {
    label: "Recommendation from a Colleague or Friend",
    value: LearnFromType.RECOMMENDATION_FROM_A_COLLEAGUE_OR_FRIEND,
  },
  {
    label: "Other",
    value: LearnFromType.OTHER,
  },
]

export const SLUGS = {
  step2: {
    slug: "step2",
    permanentSameAsCurrent: {
      slug: "permanentSameAsCurrent",
      options: {
        yes: 1,
        no: 2,
      },
    },
    referred: {
      slug: "have-you-been-referred-by-someone-step2",
      options: {
        yes: "Yes",
        no: "No",
      },
    },
    referredBy: {
      slug: "who-have-you-been-referred-by-step2",
      options: {
        friend: "Friend",
        colleague: "Colleague",
        schoolManagement: "School Management",
        familyMember: "Family Member",
        other: "Others (please specify)",
      },
    },
  },
  step3: {
    slug: "step3",
    gender: {},
    hispanicOrLatino: {
      slug: "are-you-hispanic-or-latino-step3",
      options: {
        yes: "Yes",
        no: "No",
        preferNotToDisclose: "Prefer Not to Disclose",
      },
    },
    ethnicity: {
      slug: "please-select-your-ethnicity-step3",
      options: [
        { text: ethnicityOptions.asianBlack },
        { text: ethnicityOptions.africanAmerican },
        { text: ethnicityOptions.alaskaNative },
        { text: ethnicityOptions.nativeHawaiian },
        { text: ethnicityOptions.pacificIslander },
        { text: ethnicityOptions.whiteAmericanIndian },
        { text: ethnicityOptions.preferNotToDisclose },
        { text: ethnicityOptions.other },
      ],
    },
  },
  step8: {
    slug: "step8",
    applyForScholarship: {
      slug: "do-you-want-to-apply-for-academic-scholarship-step8",
      description:
        "Your eligibility for academic scholarships and the eligible amount is determined by our admission team based on your profile and interview performance. The details will be share with you on application acceptance if you are eligible for the scholarship.",
      options: {
        no: "No",
      },
    },
    existingScholarship: {
      slug: "do-you-have-existing-scholarship-letter-from-itu-step8",
      description:
        "Please note that these are special scholarships and are different from the academic scholarship.",
      options: {
        yes: "Yes",
        no: "No",
      },
    },
    uploadDocuments: {
      slug: "please-choose-relevant-option-and-upload-your-docu-step8",
      options: {
        schoolNominationGrant: "School Nomination Grant For Advanced Studies",
        ITOScholarship: "International Teachers' Olympiad Top 800 Scholarship",
      },
    },
    learnedAboutProgramme: {
      options: {
        other: "Other",
      },
    },
  },
  step9: {
    slug: "step9",
    howDidYouLearn: {
      slug: "how-did-you-learn-about-this-masters-degree-progr-step9",
    },
  },
  step10: {
    slug: "step10",
    learningAccommodations: {
      additionalAnswerSlug:
        "do-you-require-any-learning-accomodations-step10-additional",
      slug: "do-you-require-any-learning-accomodations-step10",
      options: {
        yes: "Yes",
        no: "No",
      },
    },
    suspended: {
      additionalAnswerSlug:
        "have-you-ever-been-suspended-or-fired-in-your-pre-step10-additional",
      slug: "have-you-ever-been-suspended-or-fired-in-your-pre-step10",
      options: {
        yes: "Yes",
        no: "No",
      },
    },
    beenArrested: {
      additionalAnswerSlug:
        "have-you-been-arrested-or-convicted-for-any-crime-step10-additional",
      slug: "have-you-been-arrested-or-convicted-for-any-crime-step10",
      options: {
        yes: "Yes",
        no: "No",
      },
    },
  },
}

export const findOptionFromResponse = (
  options: Option[],
  response: Response
): Option | undefined => {
  return options.find(o => o.id === response.option)
}
export const getApplicationTag = ({
  applicationStatus,
  interviewStatus,
  reviewStatus,
}: {
  applicationStatus?: ApplicationStatus
  interviewStatus?: InterviewStatus | null
  reviewStatus?: ReviewStatus
}): { color: BadgeProps["color"]; text: string } | null => {
  if (interviewStatus) {
    switch (interviewStatus) {
      case InterviewStatus.ACCEPTED:
        return { color: "success", text: "Accepted via Interview" }
      case InterviewStatus.REJECTED:
        return { color: "critical", text: "Rejected via Interview" }
      case InterviewStatus.CANCELLED:
        return { color: "critical", text: "Interview Cancelled" }
      case InterviewStatus.SCHEDULED:
        return { color: "warning", text: "Interview Scheduled" }
      case InterviewStatus.NO_SHOW:
        return { color: "critical", text: "Skipped Interview" }
      default:
        return null
    }
  }
  if (reviewStatus) {
    switch (reviewStatus) {
      case ReviewStatus.CHANGES_REQUESTED:
        return { color: "warning", text: "Changes Requested" }
      case ReviewStatus.CHANGES_STARTED:
        return { color: "warning", text: "Changes Requested" }
      case ReviewStatus.CHANGES_DONE:
        return { color: "warning", text: "Changes Done" }
      case ReviewStatus.REVIEW_STARTED:
        return { color: "primary", text: "Review Application" }
      // case ReviewStatus.INTERVIEW_REQUESTED:
      //   return { color: "onSurface", text: "Interview Pending" }
      case ReviewStatus.ACCEPTED:
        return { color: "success", text: "Accepted" }
      case ReviewStatus.REJECTED:
        return { color: "critical", text: "Rejected" }
      default:
        return null
    }
  }

  if (applicationStatus && applicationStatus === ApplicationStatus.REJECTED) {
    return { color: "critical", text: "Rejected" }
  }
  if (applicationStatus && applicationStatus === ApplicationStatus.ACCEPTED) {
    return { color: "success", text: "Accepted" }
  }
  return null
}
