import { Checkbox, TextArea } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Triangle } from "utils/icons"

const useStyles = createUseStyles(theme => ({
  mainContainer: {
    position: "relative",
  },
  triangle: {
    color: theme.colors.warning[100],
    fill: theme.colors.warning[100],
    left: "11.56px",
    top: "-15px",
    position: "absolute",
  },
  commentContainer: {
    backgroundColor: theme.colors.warning[100],
    position: "relative",
  },
  commentTextArea: {
    width: "100%",
    "& div:first-child": {
      borderColor: theme.colors.warning[200],
      "&:hover": {
        boxShadow: `${theme.colors.warning[400]} 0px 0px 0px 0.5px`,
        borderColor: `${theme.colors.warning[400]} !important`,
      },
      "&:focus": {
        boxShadow: `${theme.colors.warning[400]} 0px 0px 0px 0.5px`,
        borderColor: `${theme.colors.warning[400]} !important`,
      },
      "&:focus-within": {
        boxShadow: `${theme.colors.warning[400]} 0px 0px 0px 0.5px`,
        borderColor: `${theme.colors.warning[400]} !important`,
      },
    },
  },
  commentViewDiv: {
    border: `1px solid ${theme.colors.warning[200]}`,
    padding: "12px 16px",
    background: "white",
    cursor: "pointer",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: "4px",
    whiteSpace: "pre-line",
    "&:hover": {
      boxShadow: `${theme.colors.warning[400]} 0px 0px 0px 0.5px`,
      borderColor: `${theme.colors.warning[400]} !important`,
    },
  },
  disablePointerEvents: {
    pointerEvents: "none",
  },
}))

type Props = {
  children: React.ReactNode
  className?: string
  slug: string
  text: string | null | undefined
  onChange: (str: string | null) => void
  hideCheckBox?: boolean
  disabled?: boolean
  error?: string
}
const AuditField: React.FC<Props> = ({
  children,
  className,
  slug,
  text,
  hideCheckBox,
  disabled,
  onChange,
  error,
}) => {
  const classes = useStyles()

  const [checked, setChecked] = useState<boolean | null>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [comment, setComment] = useState<string | null | undefined>(text)
  const [ref, setRef] = useState<HTMLTextAreaElement | HTMLInputElement | null>(
    null
  )
  const maxLength = 512

  useEffect(() => {
    if (text || text === null) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [text])

  const displayComment = () => {
    if (text && !isFocused) {
      return (
        <div
          className={classes.commentViewDiv}
          role="button"
          tabIndex={0}
          onClick={() => {
            if (!disabled) setIsFocused(true)
          }}
          onKeyDown={() => {}}
        >
          {text}
        </div>
      )
    }

    if (ref) {
      ref.oninput = () => {
        ref.style.height = "auto"
        ref.style.height = `${ref.scrollHeight}px`
      }
    }
    return (
      <TextArea
        maxLength={maxLength}
        className={classes.commentTextArea}
        helperText={error}
        id={slug}
        placeholder="Add Comment"
        ref={newRef => setRef(newRef)}
        style={{
          height: ref ? `${ref.scrollHeight}px` : "42px",
        }}
        value={comment || ""}
        // This was causing issues while cancelling the changes
        // We need to figure out a way to only enable this behvaiour while the application is under_review
        // autoFocus
        onBlur={() => {
          if (comment !== undefined) {
            onChange(comment)
            setIsFocused(false)
          }
        }}
        onChange={(e: { currentTarget: { value: string } }) => {
          if (e.currentTarget.value.length < maxLength) {
            setComment(e.currentTarget.value)
          } else {
            e.currentTarget.value = e.currentTarget.value.substring(
              0,
              maxLength
            )
          }
        }}
      />
    )
  }

  return (
    <div className={clsx(classes.mainContainer, className)}>
      <div className="flex flex-col gap-1">
        <div
          className={clsx({
            "flex gap-1 sm:gap-4.5 pl-2 pr-3": !hideCheckBox,
            "px-4 sm:px-10": hideCheckBox,
          })}
        >
          {!hideCheckBox && (
            <div>
              <Checkbox
                checked={checked !== null ? checked : false}
                disabled={disabled}
                onCheckedChange={state => {
                  setChecked(!!state)
                  onChange(null)
                  setComment(null)
                  // Clear comment when checkbox is unchecked
                  if (state === false) {
                    onChange("")
                    setComment("")
                  }
                }}
              />
            </div>
          )}
          <div className={clsx("grow", classes.disablePointerEvents)}>
            {children}
          </div>
        </div>
        {checked && !hideCheckBox && (
          <div className={clsx("py-2 pl-7 pr-4", classes.commentContainer)}>
            <Triangle className={classes.triangle} />
            {displayComment()}
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(AuditField)
