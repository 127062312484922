import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  ApplicantDetails,
  ApplicationDeclaration,
  Certification,
  CommentType,
  GeneralCommentType,
  LanguageProficiency,
  PersonalDetails,
  Qualification,
  Reference,
  ReviewChangeItemType,
  ReviewStatus,
  WorkExperience,
} from "api/resources/applications/types"
import { Country, LanguageProficiencyChoices } from "api/resources/global/types"
import { GlobalContext } from "components/GlobalState"
import {
  createContext,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { ActionEnum } from "utils/action"
import { SLUGS } from "utils/applicationForm"
import { getAuthInfo } from "utils/auth"
import { applicationFormSlugs } from "utils/constants"
import { mapErrors } from "utils/helpers"
import useResources from "utils/hooks/useResources"
import { routes } from "utils/routes"

export enum GeneralCommentSections {
  EDUCATIONAL = "educational",
  EMPLOYMENT = "employment",
}

type GeneralComments = {
  type: GeneralCommentSections
  comment: GeneralCommentType
}

export type ApplicationContextType = {
  isReviewDisabled: boolean
  applicationId: string
  personalCheckList: CommentType[]
  employmentCheckList: CommentType[]
  setEmploymentCheckList: React.Dispatch<SetStateAction<CommentType[]>>
  setPersonalCheckList: React.Dispatch<SetStateAction<CommentType[]>>
  educationCheckList: CommentType[]
  setEducationCheckList: React.Dispatch<SetStateAction<CommentType[]>>
  removeGeneralComment: (type: GeneralComments["type"]) => void
  generalComments: GeneralComments[]
  updateGeneralComment: (
    type: GeneralComments["type"],
    comment: GeneralComments["comment"]
  ) => void
  getGeneralCommentByType: (type: GeneralComments["type"]) => string
  reviewList: CommentType[]
  applicantData: PersonalDetails
  questionList: ApplicantDetails["questionnaireQuestions"]
  languageProficiencies: LanguageProficiency[]
  qualifications: Qualification[]
  certifications: Certification[]
  workExperiences: WorkExperience[]
  references: Reference[]
  countries: Country[]

  crmIdentifiers: ApplicantDetails["crmIdentifiers"] | null
  isLoading?: boolean
  applicantDetails: ApplicantDetails | undefined
  refetchApplication: () => void
  isFetching?: boolean
  setLocalDeclarations: React.Dispatch<SetStateAction<ApplicationDeclaration[]>>
  localDeclarations: ApplicationDeclaration[]
  declarations: ApplicationDeclaration[]
  canTakeActions: boolean
  reviewer: ApplicantDetails["reviewer"] | null
}

export const proficiencyChoices = [
  {
    label: "Elementary",
    value: LanguageProficiencyChoices.ELEMENTARY,
  },
  {
    label: "Limited Working",
    value: LanguageProficiencyChoices.LIMITED_WORKING,
  },
  {
    label: "Professional Working",
    value: LanguageProficiencyChoices.PROFESSIONAL_WORKING,
  },
  {
    label: "Full Professional",
    value: LanguageProficiencyChoices.FULL_PROFESSIONAL,
  },
  {
    label: "Native/Bi-lingual",
    value: LanguageProficiencyChoices.NATIVE,
  },
]

export const ApplicationContext = createContext<ApplicationContextType>({
  isReviewDisabled: false,
  generalComments: [],
  removeGeneralComment: () => {},
  updateGeneralComment: () => {},
  getGeneralCommentByType: () => "",
  applicationId: "",
  personalCheckList: [],
  setPersonalCheckList: () => {},
  educationCheckList: [],
  employmentCheckList: [],
  setEmploymentCheckList: () => {},
  setEducationCheckList: () => {},
  reviewList: [],
  applicantData: {
    id: 0,
    title: null,
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: null,
    gender: undefined,
    nationalIdNumber: null,
    nationalityId: null,
    mobilePhoneNumber: null,
    photo: undefined,
    passportNumber: null,
    whatsappPhoneNumber: null,
    homePhoneNumber: null,
    workPhoneNumber: null,
    currentResidence: undefined,
    permanentResidence: undefined,
    permanentSameAsCurrent: 0,
    addresses: [],
  },
  questionList: [],
  languageProficiencies: [],
  qualifications: [],
  certifications: [],
  workExperiences: [],
  references: [],
  countries: [],
  crmIdentifiers: null,
  isLoading: undefined,
  applicantDetails: undefined,
  refetchApplication: () => {},
  isFetching: undefined,
  localDeclarations: [],
  setLocalDeclarations: () => {},
  declarations: [],
  canTakeActions: false,
  reviewer: null,
})

export const ApplicationContextProvider = ({
  applicationId,
  children,
}: {
  applicationId: string

  children: React.ReactNode
}) => {
  const [personalCheckList, setPersonalCheckList] = useState<CommentType[]>([])
  const [educationCheckList, setEducationCheckList] = useState<CommentType[]>(
    []
  )
  const [employmentCheckList, setEmploymentCheckList] = useState<CommentType[]>(
    []
  )
  const [applicantData, setApplicantData] = useState<PersonalDetails>({
    id: 0,
    title: null,
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: null,
    gender: undefined,
    nationalIdNumber: null,
    nationalityId: null,
    mobilePhoneNumber: null,
    photo: undefined,
    passportNumber: null,
    whatsappPhoneNumber: null,
    homePhoneNumber: null,
    workPhoneNumber: null,
    currentResidence: undefined,
    permanentResidence: undefined,
    permanentSameAsCurrent: 0,
    addresses: [],
  })

  const navigate = useNavigate()
  const { actions } = useContext(GlobalContext)
  const auth = getAuthInfo()

  const [reviewList, setReviewList] = useState<CommentType[]>([])

  const [generalComments, setGeneralComments] = useState<GeneralComments[]>([])
  const [localDeclarations, setLocalDeclarations] = useState<
    ApplicationDeclaration[]
  >([])

  const { countries: countriesList } = useResources(["countries"])

  const application = useQuery({
    queryKey: queries.application.retrieve(applicationId).queryKey,
    queryFn: () =>
      api.applications.retrieve({
        urlParams: { applicationId },
        params: {
          fields: [
            "id",
            "questionnaire_questions",
            "submit_time",
            "product_slug",
            "crm_identifiers",
          ],
        },
      }),
    enabled: Boolean(applicationId),
  })

  const declarations = useMemo(
    () => application.data?.applicationDeclaration ?? [],
    [application.data?.applicationDeclaration]
  )
  const canTakeActions = useMemo(
    () =>
      actions.includes(ActionEnum.reviewAllApplications) ||
      (application.data?.reviewer.uuid === auth.user.uuid &&
        actions.includes(ActionEnum.reviewApplication)),
    [actions, application.data?.reviewer.uuid, auth.user.uuid]
  )

  const references = useMemo(
    () => application.data?.references ?? [],
    [application.data?.references]
  )
  const crmIdentifiers = useMemo(
    () => application.data?.crmIdentifiers ?? null,
    [application.data?.crmIdentifiers]
  )
  const questionList = useMemo(
    () => application.data?.questionnaireQuestions ?? [],
    [application.data?.questionnaireQuestions]
  )

  const reviewer = useMemo(
    () => application.data?.reviewer || null,
    [application.data?.reviewer]
  )

  useEffect(() => {
    if (application.isFetching === true) return
    if (application.data) {
      if (application.data.productSlug === applicationFormSlugs.med) {
        navigate(routes.medReview.replace(":applicationId", applicationId), {
          replace: true,
        })
      }

      setPersonalDetailsData(application.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application.isFetching])

  const profileDetails = useQuery({
    queryKey: queries.application.retrieveApplication(applicationId).queryKey,
    queryFn: () =>
      api.applications.retrieveProfile({
        urlParams: { applicationId },
        params: {
          fields: [
            "language_proficiencies",
            "qualifications",
            "certifications",
            "work_experiences",
          ],
        },
      }),
    enabled: Boolean(applicationId),
  })
  const languageProficiencies = useMemo(
    () => profileDetails.data?.languageProficiencies ?? [],
    [profileDetails.data?.languageProficiencies]
  )
  const qualifications = useMemo(
    () => profileDetails.data?.qualifications ?? [],
    [profileDetails.data?.qualifications]
  )
  const certifications = useMemo(
    () => profileDetails.data?.certifications ?? [],
    [profileDetails.data?.certifications]
  )
  const workExperiences = useMemo(
    () => profileDetails.data?.workExperiences ?? [],
    [profileDetails.data?.workExperiences]
  )

  const setPersonalDetailsData = (data: ApplicantDetails) => {
    const permanentSameAsCurrent = data.addresses.every(
      ({ isCurrent, isPermanent }) => isCurrent && isPermanent
    )
    const currentAddress = data.addresses.find(({ isCurrent }) => isCurrent)
    const permaAddress = permanentSameAsCurrent
      ? null
      : data.addresses.find(({ isPermanent }) => isPermanent)

    setApplicantData({
      ...data.applicationDetail,
      addresses: [...data.addresses],
      ...(currentAddress && { currentResidence: { ...currentAddress } }),
      ...(permaAddress && { permanentResidence: { ...permaAddress } }),
      ...{
        permanentSameAsCurrent: permanentSameAsCurrent
          ? SLUGS.step2.permanentSameAsCurrent.options.yes
          : SLUGS.step2.permanentSameAsCurrent.options.no,
      },
    })
  }

  const isReviewDisabled = useCallback(() => {
    if (reviewList.length > 0) return true

    if (!application.data?.review?.status) return true

    switch (application.data.review.status) {
      case ReviewStatus.NOT_STARTED:
      case ReviewStatus.REVIEW_STARTED:
      case ReviewStatus.CHANGES_DONE: // If user has done the changes, we are allowed to review again.
        return false

      default:
        return true
    }
  }, [application.data, reviewList.length])

  const reviewChangeList = useQuery({
    queryKey: queries.application.reviewChangeList(applicationId).queryKey,
    queryFn: () =>
      api.applications.review.list({
        params: { application_id: applicationId },
      }),
    enabled: Boolean(applicationId) && Boolean(application.data?.review),
  })

  useEffect(() => {
    if (reviewChangeList.isFetching === true) return
    const getReviewList = (item: CommentType[]) => {
      const applicationData = item.filter(
        i => i.itemType === ReviewChangeItemType.applicationdetail
      )
      const educationData = item.filter(
        i =>
          i.itemType === ReviewChangeItemType.qualification ||
          i.itemType === ReviewChangeItemType.certification
      )
      const employmentData = item.filter(
        i =>
          i.itemType === ReviewChangeItemType.workexperience &&
          Boolean(i.itemId)
      )

      setPersonalCheckList(applicationData)
      setEducationCheckList(educationData)
      setEmploymentCheckList(employmentData)
    }
    if (reviewChangeList.isSuccess && reviewChangeList.data) {
      setReviewList(reviewChangeList.data)
      getReviewList(reviewChangeList.data)
    }
    if (reviewChangeList.isError && reviewChangeList.error) {
      mapErrors(reviewChangeList.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reviewChangeList.isError,
    reviewChangeList.isSuccess,
    reviewChangeList.isFetched,
    reviewChangeList.isFetching,
  ])

  const updateGeneralComment = (
    type: GeneralComments["type"],
    comment: GeneralComments["comment"]
  ) => {
    setGeneralComments(comments => [
      ...comments.filter(comment => comment.type !== type),
      {
        type,
        comment,
      },
    ])
  }
  const removeGeneralComment = (type: GeneralComments["type"]) => {
    setGeneralComments(comments =>
      comments.filter(comment => comment.type !== type)
    )
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getGeneralCommentByType = (type: GeneralComments["type"]) => {
    return (
      generalComments.find(v => v.type === type && v.comment.itemId === null)
        ?.comment.comment || ""
    )
  }
  const contextValues = useMemo(
    () => ({
      personalCheckList,
      setPersonalCheckList,
      educationCheckList,
      employmentCheckList,
      setEmploymentCheckList,
      setEducationCheckList,
      applicationId,
      applicantData,
      questionList,
      reviewList,
      languageProficiencies,
      qualifications,
      certifications,
      workExperiences,
      references,
      countries: countriesList ?? [],
      isReviewDisabled: isReviewDisabled(),
      canTakeActions,
      generalComments,
      updateGeneralComment,
      removeGeneralComment,
      getGeneralCommentByType,
      crmIdentifiers,
      applicantDetails: application.data,
      isLoading: application.isLoading,
      isFetching: application.isFetching,
      refetchApplication: async () => {
        await application.refetch()
        setGeneralComments([])
        await reviewChangeList.refetch()
      },
      localDeclarations,
      setLocalDeclarations,
      declarations,
      reviewer,
    }),
    [
      reviewer,
      personalCheckList,
      educationCheckList,
      employmentCheckList,
      applicationId,
      applicantData,
      questionList,
      reviewList,
      languageProficiencies,
      qualifications,
      certifications,
      workExperiences,
      references,
      countriesList,
      isReviewDisabled,
      canTakeActions,
      generalComments,
      getGeneralCommentByType,
      crmIdentifiers,
      application,
      localDeclarations,
      declarations,
      reviewChangeList,
    ]
  )
  return (
    <ApplicationContext.Provider value={contextValues}>
      {children}
    </ApplicationContext.Provider>
  )
}
