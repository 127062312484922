import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  IconButton,
} from "@suraasa/placebo-ui"
import { Xmark } from "iconoir-react"
import { useState } from "react"

import ImageViewer from "./ImageViewer"

type Props = {
  url: string | undefined
  className?: string
  children: React.ReactNode
}

export const isImage = (url: string | undefined | null) => {
  if (!url) return false

  const path = new URL(url).pathname
  const ext = path.split(".").reverse()[0]
  return ext
    ? ["jpg", "jpeg", "png", "webp"].includes(ext.toLowerCase())
    : false
}

const FileViewer = ({ url, className, children }: Props) => {
  const [showViewer, setShowViewer] = useState(false)

  if (!url) {
    return null
  }

  if (!isImage(url)) {
    throw new Error("the supplied url is not an image")
  }

  return (
    <>
      <button
        className={className}
        onClick={() => {
          setShowViewer(true)
        }}
      >
        {children}
      </button>
      <Dialog
        open={showViewer}
        onOpenChange={() => {
          setShowViewer(false)
        }}
      >
        <DialogContent className="size-full !max-h-none !max-w-none !overflow-hidden rounded-none bg-white">
          <DialogBody className="overflow-hidden">
            <ImageViewer url={url} />
          </DialogBody>

          <DialogClose className="absolute right-2 top-2">
            <IconButton color="black">
              <Xmark
                className="!size-4"
                onClick={() => {
                  setShowViewer(false)
                }}
              />
            </IconButton>
          </DialogClose>
        </DialogContent>
      </Dialog>

      {/* <Dialog
        fullScreen={true}
        open={showViewer}
        onRequestClose={() => {
          setShowViewer(false)
        }}
      >
        <DialogContent className="!p-0">
          <ImageViewer url={url} />
        </DialogContent>
        <div className="absolute right-2 top-2">
          <IconButton color="black" className="bg-white">
            <Cancel
              onClick={() => {
                setShowViewer(false)
              }}
            />
          </IconButton>
        </div>
      </Dialog> */}
    </>
  )
}

export default FileViewer
