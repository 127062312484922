export enum ActionEnum {
  auth = "ATH1",
  // createApplication = "APP001", // Not for admin platform
  viewAllSubmittedApplications = "APP002",
  // viewApplication = "APP003", // Not for admin platform
  // listAllApplications = "APP004", // Not for admin platform
  // viewAllApplications = "APP005", // Not for admin platform
  // editApplication = "APP006", // Not for admin platform
  // submitApplication = "APP008", // Not for admin platform
  assignApplication = "APP009",
  reviewApplication = "APP010",
  reviewAllApplications = "APP011",
  revertApplicationStatus = "APP012",
}
