import api from "api"
import { Country, Currency, Subject } from "api/resources/global/types"
import { APIError } from "api/utils"
import { useEffect, useState } from "react"
import { mapErrors } from "utils/helpers"

type Resources = "currencies" | "subjects" | "countries" | "curricula"

type Props = Resources[]

/**
 *
 * @param resource
 * @returns values of resources passed,
 * returns empty array if a specific resource is not passed
 */
const useResources = (resource: Props) => {
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [countries, setCountries] = useState<Country[]>([])
  const [subjects, setSubjects] = useState<Subject[]>([])

  useEffect(() => {
    const fetchResources = async () => {
      if (resource.includes("currencies")) {
        const listCurrencies = await api.global.listCurrencies({
          params: { page: -1 },
        })

        if (listCurrencies.isSuccessful) {
          setCurrencies(listCurrencies.data)
        }
      }
      if (resource.includes("countries")) {
        const listCountries = await api.global.listCountries({
          params: { page: -1 },
        })
        if (listCountries instanceof APIError) {
          mapErrors(listCountries)
        } else {
          setCountries(listCountries)
        }
      }

      if (resource.includes("subjects")) {
        const subjects = await api.global.listSubjects({
          params: { page: -1 },
        })

        if (subjects.isSuccessful) {
          setSubjects(subjects.data)
        }
      }
    }
    fetchResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    currencies,
    countries,
    subjects,
  }
}

export default useResources
