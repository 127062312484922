import { ToastProvider } from "@suraasa/placebo-ui"
import { theme } from "@suraasa/placebo-ui-legacy"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import AdminRoute from "components/AdminRoute"
import GetActions from "components/auth/GetActions"
import AdminDashboard from "components/dashboard/Dashboard"
import GlobalState from "components/GlobalState"
import ErrorPage from "components/shared/ErrorPage"
import React from "react"
import { ThemeProvider } from "react-jss"
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { routes } from "utils/routes"
import MEdReview from "views/application/masters-in-education"
import ApplicationReview from "views/application/Review"
import SSO from "views/auth/SSO"
import SentryTest from "views/SentryTest"

const AuthRoutes = () => {
  const auth = getAuthInfo()

  return auth ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: routes.login,
        search: `?next=${window.location.pathname}`,
      }}
    />
  )
}

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        /**
         * We don't want failed requests to be retried by default
         * We can enable this on a per-API basis
         */
        retry: false,
      },
    },
  })
  return (
    <Router>
      {/* @ts-ignore Can't seem to fix this */}
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route element={<SSO />} path={routes.login} />

            <Route element={<SentryTest />} path={routes.error} />

            <Route element={<AuthRoutes />}>
              <Route element={<GlobalState />} path="/">
                <Route element={<GetActions />} path="/">
                  <Route
                    element={<Navigate to={routes.dashboard} replace />}
                    path="/"
                  />
                  <Route element={<MEdReview />} path={routes.medReview} />
                  <Route
                    element={<ApplicationReview />}
                    path={routes.applicationReview}
                  />

                  <Route element={<AdminRoute />} path="/">
                    {/* Any routes which are only for admin go here */}
                    <Route
                      element={<AdminDashboard />}
                      path={routes.dashboard}
                    />
                  </Route>
                </Route>
              </Route>

              <Route element={<ErrorPage errorCode={404} />} path="*" />
            </Route>

            <Route element={<ErrorPage errorCode={404} />} path="*" />
          </Routes>
        </QueryClientProvider>
        <ToastProvider />
      </ThemeProvider>
    </Router>
  )
}

export default App
