import { cn } from "@suraasa/placebo-ui"
import React from "react"

type Props = {
  className?: string
  children: React.ReactNode
}

const PageContainer = (props: Props) => {
  return (
    <div className={cn("mx-auto max-w-[1920px] px-2", props.className)}>
      {props.children}
    </div>
  )
}

export default PageContainer
