import { InputLabel, InputLabelProps } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  customInputLabel: {
    "& .Typography-root": {
      ...theme.typography.strong,
      textTransform: "initial",
    },
  },
}))

const CustomInputLabel: React.FC<InputLabelProps> = ({
  className,
  ...props
}) => {
  const classes = useStyles()

  return (
    <InputLabel
      {...props}
      className={clsx(classes.customInputLabel, className)}
    />
  )
}

export default CustomInputLabel
