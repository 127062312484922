import { Typography } from "@suraasa/placebo-ui"
import {
  QualificationFormat,
  ReviewChangeItemType,
} from "api/resources/applications/types"
import { AttachmentType, ModeOfLearning } from "api/resources/global/types"
import AuditField from "components/application/AuditField"
import Card from "components/shared/Card"
import omit from "lodash/omit"
import { useContext, useState } from "react"
import {
  modeOfLearningTypeOptions,
  qualificationFormatChoices,
} from "utils/constants"
import { formatDate } from "utils/helpers"
import {
  ApplicationContext,
  GeneralCommentSections,
} from "views/application/context/ApplicationContext"

import AcademicEvidence from "./AcademicEvidence"
import GeneralComments, { AddCommentsButton } from "./GeneralComments"

const EducationCard = () => {
  const {
    applicationId,
    educationCheckList,
    setEducationCheckList,
    isReviewDisabled,
    canTakeActions,
    qualifications,
    certifications,
    updateGeneralComment,
    removeGeneralComment,
    getGeneralCommentByType,
    reviewList,
  } = useContext(ApplicationContext)
  const [showGeneralComment, setShowGeneralComment] = useState(false)

  // useEffect(() => {
  //   localForage
  //     .getItem<CheckListType>(applicationId)
  //     .then(item => {
  //       if (item && item.education) {
  //         setEducationCheckList(item.education)
  //       }
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   if (!isReviewDisabled) {
  //     if (educationCheckList.length > 0) {
  //       console.log("setting educationCheckList", educationCheckList)
  //       localForage
  //         .getItem<CheckListType>(applicationId)
  //         .then(item => {
  //           localForage.setItem(applicationId, {
  //             ...item,
  //             education: educationCheckList,
  //           })
  //         })
  //         .catch(err => {
  //           console.error(err)
  //         })
  //     } else {
  //       localForage
  //         .getItem<CheckListType>(applicationId)
  //         .then(item => {
  //           if (item && item.education) {
  //             delete item.education
  //             localForage.setItem(applicationId, item)
  //           }
  //         })
  //         .catch(err => {
  //           console.error(err)
  //         })
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [educationCheckList, isReviewDisabled])

  const removeCommentFromList = (
    itemId: number,
    itemType: ReviewChangeItemType
  ) => {
    setEducationCheckList(prevEvents =>
      prevEvents.filter(item => {
        if (item.itemId === itemId && item.itemType === itemType) return false

        return true
      })
    )
  }

  const handleOnChange = (
    entry: string | null,
    itemId: number,
    itemType: ReviewChangeItemType
  ) => {
    const fieldNameObj = educationCheckList.find(
      i => i.itemId === itemId && i.itemType === itemType
    )
    if (fieldNameObj && typeof entry === "string" && entry.length === 0) {
      removeCommentFromList(itemId, itemType)
      return
    }
    if (
      educationCheckList.find(
        i => i.itemId === itemId && i.itemType === itemType
      )
    ) {
      if (entry) {
        setEducationCheckList(prevState =>
          prevState.map(i =>
            i.itemId === itemId && i.itemType === itemType
              ? omit({ ...i, comment: entry }, "error")
              : i
          )
        )
      }
    } else {
      setEducationCheckList(prevState => [
        ...prevState,
        {
          itemId,
          itemType,
          comment: entry,
        },
      ])
    }
  }

  const getModeOfLearning = (mode: ModeOfLearning) => {
    const selected = modeOfLearningTypeOptions.filter(
      a => a.value === mode && a
    )[0]
    return selected?.label ?? ""
  }

  const getQualificationFormat = (format?: QualificationFormat) => {
    const selected = qualificationFormatChoices.filter(
      a => a.value === format && a
    )[0]
    return selected?.label ?? ""
  }

  const onCommentChange = (comment: string) => {
    if (comment.length === 0) {
      removeGeneralComment(GeneralCommentSections.EDUCATIONAL)
    } else {
      updateGeneralComment(GeneralCommentSections.EDUCATIONAL, {
        itemId: null,
        itemType: ReviewChangeItemType.educationalbackground,
        comment: comment,
      })
    }
  }

  const updatedComment = getGeneralCommentByType(
    GeneralCommentSections.EDUCATIONAL
  )
  const reviewComment =
    reviewList.find(
      v => v.itemType === ReviewChangeItemType.educationalbackground
    )?.comment || ""

  return (
    <>
      <Card className="mt-4">
        <div className="gap-3 px-2 pt-2">
          <div className="flex items-center justify-between">
            <Typography variant="title3">Educational Background</Typography>
            <div>
              <AddCommentsButton
                disabled={isReviewDisabled}
                show={showGeneralComment}
                onClick={() => setShowGeneralComment(!showGeneralComment)}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2 pt-2">
            {qualifications.length === 0 && certifications.length === 0 && (
              <Typography className="mb-2 text-center" variant="strong">
                No Data Available
              </Typography>
            )}
            {qualifications.length > 0 && (
              <>
                <Typography
                  className="pl-9"
                  color="onSurface.500"
                  variant="title3"
                >
                  Academic Qualifications
                </Typography>
                {qualifications.map(data => (
                  <>
                    <AuditField
                      disabled={isReviewDisabled}
                      error={
                        educationCheckList.find(
                          i =>
                            i.itemId === data.id &&
                            i.itemType === ReviewChangeItemType.qualification
                        )?.error || ""
                      }
                      hideCheckBox={!canTakeActions}
                      key={data.id}
                      slug={data.id.toString()}
                      text={
                        educationCheckList.find(
                          i =>
                            i.itemId === data.id &&
                            i.itemType === ReviewChangeItemType.qualification
                        )?.comment
                      }
                      onChange={entry =>
                        handleOnChange(
                          entry,
                          data.id,
                          ReviewChangeItemType.qualification
                        )
                      }
                    >
                      <Typography color="onSurface.800" variant="strong">
                        {data.name}
                      </Typography>

                      <Typography color="onSurface.800" variant="body">
                        {[
                          data.qualificationLevel?.name,
                          data.qualificationField?.name,
                        ]
                          .filter(Boolean)
                          .join(" • ")}
                      </Typography>

                      <Typography color="onSurface.500" variant="smallBody">
                        {getModeOfLearning(data.modeOfLearning)}{" "}
                        {getQualificationFormat(data.qualificationFormat)
                          ? `(${getQualificationFormat(
                              data.qualificationFormat
                            )} Learning)`
                          : ""}
                      </Typography>
                      <Typography color="onSurface.500" variant="smallBody">
                        {[
                          data.instituteName,
                          [data.state?.name, data.country?.name]
                            .filter(Boolean)
                            .join(", "),
                        ]
                          .filter(Boolean)
                          .join(" • ")}
                      </Typography>
                      <Typography color="onSurface.500" variant="smallBody">
                        {formatDate(data.startDate, "MMMM yyyy")}

                        {data.endDate && (
                          <>
                            <span className="mx-1">-</span>
                            {formatDate(data.endDate, "MMMM yyyy")}
                          </>
                        )}
                      </Typography>
                    </AuditField>
                    {data.certificates.length > 0 && (
                      <div className="mb-2 ml-10 mt-1 flex gap-2 overflow-auto">
                        {data.certificates.map((item, index) => (
                          <AcademicEvidence
                            name={item.attachmentName}
                            url={item.attachment || item.url}
                            type={getAttachmentTypeDisplay(item.attachmentType)}
                            key={index}
                          />
                        ))}
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
            {certifications.length > 0 && (
              <>
                <Typography
                  className="pl-9"
                  color="onSurface.500"
                  variant="title3"
                >
                  Certifications
                </Typography>
                {certifications.map(data => (
                  <>
                    <AuditField
                      disabled={isReviewDisabled}
                      error={
                        educationCheckList.find(
                          i =>
                            i.itemId === data.id &&
                            i.itemType === ReviewChangeItemType.certification
                        )?.error || ""
                      }
                      hideCheckBox={!canTakeActions}
                      key={data.id}
                      slug={data.id.toString()}
                      text={
                        educationCheckList.find(
                          i =>
                            i.itemId === data.id &&
                            i.itemType === ReviewChangeItemType.certification
                        )?.comment
                      }
                      onChange={entry =>
                        handleOnChange(
                          entry,
                          data.id,
                          ReviewChangeItemType.certification
                        )
                      }
                    >
                      <Typography color="onSurface.800" variant="strong">
                        {data.name}
                      </Typography>

                      <Typography color="onSurface.800" variant="body">
                        {data.instituteName}
                      </Typography>
                      <Typography color="onSurface.500" variant="smallBody">
                        {getModeOfLearning(data.modeOfLearning)}
                      </Typography>
                      <Typography color="onSurface.500" variant="smallBody">
                        {formatDate(data.completionDate, "MMMM yyyy")}
                        {data.expirationDate &&
                          data.expirationDate &&
                          ` - ${formatDate(data.expirationDate, "MMMM yyyy")}`}
                      </Typography>
                    </AuditField>
                    {data.certificates.length > 0 && (
                      <div className="mb-2 ml-10 mt-1 flex gap-2 overflow-auto">
                        {data.certificates.map((item, index) => (
                          <AcademicEvidence
                            name={item.attachmentName}
                            url={item.attachment || item.url}
                            type={getAttachmentTypeDisplay(item.attachmentType)}
                            key={index}
                          />
                        ))}
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
        <GeneralComments
          showCommentBox={reviewComment ? true : showGeneralComment}
          placeholder="Add Comments for Educational Background"
          onCommentChange={onCommentChange}
          comment={updatedComment || reviewComment}
          disabled={!!reviewComment}
        />
        {!showGeneralComment && !reviewComment && <div className="pb-2" />}
      </Card>
    </>
  )
}
export default EducationCard

const getAttachmentTypeDisplay = (type: AttachmentType | null) => {
  if (type === AttachmentType.DEGREE) return "Degree"
  if (type === AttachmentType.MARKSHEET) return "Marksheet/Transcript"
  return ""
}
