import { Button, TextArea } from "@suraasa/placebo-ui"
import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  commentTextArea: {
    width: "100%",
    "& div:first-child": {
      borderColor: theme.colors.warning[200],
      "&:hover": {
        boxShadow: `${theme.colors.warning[400]} 0px 0px 0px 0.5px`,
        borderColor: `${theme.colors.warning[400]} !important`,
      },
      "&:focus": {
        boxShadow: `${theme.colors.warning[400]} 0px 0px 0px 0.5px`,
        borderColor: `${theme.colors.warning[400]} !important`,
      },
      "&:focus-within": {
        boxShadow: `${theme.colors.warning[400]} 0px 0px 0px 0.5px`,
        borderColor: `${theme.colors.warning[400]} !important`,
      },
    },
  },
}))

export const AddCommentsButton = ({
  onClick,
  show,
  disabled,
}: {
  onClick: () => void
  show: boolean
  disabled: boolean
}) => {
  if (show) {
    return (
      <div>
        <Button
          variant="text"
          color="primary"
          onClick={onClick}
          disabled={disabled}
        >
          Close Comments
        </Button>
      </div>
    )
  }
  return (
    <div>
      <Button
        variant="text"
        color="primary"
        onClick={onClick}
        disabled={disabled}
      >
        Add Comments
      </Button>
    </div>
  )
}

type Props = {
  placeholder: string
  showCommentBox: boolean
  onCommentChange: (comment: string) => void
  comment: string
  disabled?: boolean
}

const GeneralComments = ({
  placeholder,
  showCommentBox,
  onCommentChange,
  comment,
  disabled,
}: Props) => {
  const classes = useStyles()

  const maxLength = 512
  const [ref, setRef] = useState<HTMLTextAreaElement | HTMLInputElement | null>(
    null
  )
  useEffect(() => {
    onCommentChange("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCommentBox])

  if (!showCommentBox) {
    return null
  }
  return (
    <div className="bg-warning-100 p-2">
      <TextArea
        maxLength={maxLength}
        className={classes.commentTextArea}
        disabled={disabled}
        placeholder={placeholder}
        ref={newRef => setRef(newRef)}
        style={{
          height: ref ? `${ref.scrollHeight}px` : "60px",
        }}
        value={comment || ""}
        autoFocus
        onChange={(e: { currentTarget: { value: string } }) => {
          if (e.currentTarget.value.length < maxLength) {
            onCommentChange(e.currentTarget.value)
          } else {
            e.currentTarget.value = e.currentTarget.value.substring(
              0,
              maxLength
            )
          }
        }}
      />
    </div>
  )
}

export default GeneralComments
