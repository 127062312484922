import { useCallback, useRef } from "react"

type Props = {
  loading: boolean
  hasNextPage?: boolean
  actionFunc: () => void
}

export default function usePaginationScroll({
  loading,
  hasNextPage,
  actionFunc,
}: Props) {
  const observer = useRef<IntersectionObserver>()

  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (loading) {
        return
      }
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(records => {
        if (records[0].isIntersecting) {
          if (hasNextPage) {
            actionFunc()
          }
        }
      })
      if (node) observer.current.observe(node)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  )

  return { ref } as const
}
