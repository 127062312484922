import { Tag, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import FileViewer, { isImage } from "components/shared/FileViewer"
import { createUseStyles } from "react-jss"
import useToggle from "utils/hooks/useToggle"
import { Cloud, Link } from "utils/icons"

const useStyles = createUseStyles(theme => ({
  preview: {
    borderRadius: "4px",
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  linkPreviewText: {
    position: "absolute",
    bottom: 0,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "100%",
    background: theme.colors.onSurface[50],
    "& p": {
      width: "calc(100% - 8px - 24px)",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  box: {
    position: "relative",
    width: 180,
    height: 120,
    background: theme.colors.onSurface[50],
  },
  height80: {
    height: "80%",
  },
  tagClass: {
    position: "absolute",
    right: 4,
    top: 4,
  },
}))

const AcademicEvidence = ({
  url,
  name,
  type,
}: {
  url: string | null
  name?: string | null
  type?: string | null
}) => {
  const classes = useStyles()
  const [imageError, toggleImageError] = useToggle(false)

  if (!url) throw new Error("url should be supplied")

  if (isImage(url)) {
    return (
      <div className="flex flex-col gap-1">
        <FileViewer className={clsx(classes.box)} url={url}>
          {imageError ? (
            <div
              className={clsx(
                "flex items-center justify-center",
                classes.height80
              )}
            >
              <Cloud color="black" height="24" width="24" />
            </div>
          ) : (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <img
              alt="link"
              className={classes.preview}
              src={url}
              onError={() => toggleImageError()}
            />
          )}
          <div className={classes.linkPreviewText}>
            <Link height="24" width="24" />
            <Typography variant="strongSmallBody">
              <p>{name}</p>
            </Typography>
          </div>
          {type && (
            <Tag className={classes.tagClass} color="onSurface" size="sm">
              {type}
            </Tag>
          )}
        </FileViewer>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-1">
      <a
        className={clsx(classes.box)}
        href={url}
        rel="noreferrer"
        target="_blank"
      >
        <div
          className={clsx("flex items-center justify-center", classes.height80)}
        >
          <Cloud color="black" height="24" width="24" />
        </div>
        <div className={classes.linkPreviewText}>
          <Link height="24" width="24" />
          <Typography variant="strongSmallBody">
            <p>{name}</p>
          </Typography>
        </div>
        {type && (
          <Tag className={classes.tagClass} color="onSurface" size="sm">
            {type}
          </Tag>
        )}
      </a>
    </div>
  )
}

export default AcademicEvidence
