import { useCallback, useState } from "react"

export type UseToggle = typeof useToggle
export type ToggleValue = (state?: boolean | undefined) => void

export default function useToggle(defaultValue = false) {
  const [value, setValue] = useState(defaultValue)

  const toggleValue = useCallback((state?: boolean) => {
    setValue(currentValue =>
      typeof state === "boolean" ? state : !currentValue
    )
  }, [])

  return [value, toggleValue] as const
}
