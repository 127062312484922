import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("application", {
  applications: () => ({
    queryKey: ["applications"],
  }),
  overview: () => ({
    queryKey: ["overview"],
  }),
  reviewersList: () => ({
    queryKey: ["reviewersList"],
  }),
  create: applicationId => ({
    queryKey: ["create", applicationId],
  }),
  updateReviewStatus: reviewId => ({
    queryKey: ["updateReviewStatus", reviewId],
  }),
  retrieve: applicationId => ({
    queryKey: ["retrieve", applicationId],
  }),
  retrieveApplication: applicationId => ({
    queryKey: ["retrieveApplication", applicationId],
  }),
  reviewChangeList: applicationId => ({
    queryKey: ["reviewChangeList", applicationId],
  }),
})
