import { Button, Tooltip, Typography } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ApplicationStatus,
  InterviewStatus,
  ReviewStatus,
} from "api/resources/applications/types"
import { APIError } from "api/utils"
import ApplicationTag, {
  getTagDetails,
  TagLabels,
} from "components/dashboard/Tools/ApplicationTag"
import { GlobalContext } from "components/GlobalState"
import BackButton from "components/shared/BackButton"
import PageContainer from "components/shared/PageContainer"
import { Xmark } from "iconoir-react"
import { useContext, useState } from "react"
import { ActionEnum } from "utils/action"
import { mapErrors } from "utils/helpers"
import { ApplicationContext } from "views/application/context/ApplicationContext"

import AcceptApplicationDialog from "./AcceptApplicationDialog"
import ApplicationInterviewDialog from "./ApplicationInterviewDialog"
import RejectApplicationDialog from "./RejectApplicationDialog"

const TitleBar = () => {
  // const navigate = useNavigate()
  const {
    canTakeActions,
    crmIdentifiers,
    applicantDetails,
    refetchApplication,
    reviewer,
  } = useContext(ApplicationContext)
  const { actions } = useContext(GlobalContext)

  const canRevertApplicationStatus = actions.includes(
    ActionEnum.revertApplicationStatus
  )

  const name = [
    applicantDetails?.applicationDetail.firstName,
    applicantDetails?.applicationDetail.lastName,
  ]
    .filter(Boolean)
    .join(" ")

  const [acceptApplicant, setAcceptApplicant] = useState(false)
  const [rejectApplicant, setRejectApplicant] = useState(false)
  const [interviewDialog, setInterviewDialog] = useState(false)

  const updateReviewStatus = useMutation({
    mutationFn: ({
      reviewId,
      status,
      reason,
    }: {
      reviewId: number
      status: ReviewStatus.ACCEPTED | ReviewStatus.REJECTED | "revert_review"
      reason?: string
    }) => {
      return api.applications.review.updateStatus({
        params: {
          review_id: reviewId,
        },
        data: { status, ...(reason && { rejectionReason: reason }) },
      })
    },
    onSuccess: () => {
      refetchApplication()
      if (acceptApplicant) toggleAcceptApplicantDialog()
      if (rejectApplicant) toggleRejectApplicantDialog()
      // navigate(routes.dashboard, { replace: true })
    },
    onError: err => {
      if (err instanceof APIError) {
        mapErrors(err)
      }
    },
  })
  const cancelChanges = useMutation({
    mutationFn: ({ reviewId }: { reviewId: number }) =>
      api.applications.review.cancelChanges({
        urlParams: { reviewId },
      }),
    onSuccess: async () => {
      await refetchApplication()

      // setTimeout(() => {
      //   localForage
      //     .removeItem(applicationId)
      //     .then(() => {
      //       refetchApplication()
      //     })
      //     .catch(err => {
      //       toast.error(err)
      //       console.error(err)
      //     })
      // }, 1000)
    },
    onError: err => {
      if (err instanceof APIError) {
        mapErrors(err)
      }
    },
  })

  const cancelInterview = useMutation({
    mutationFn: ({ interviewId }: { interviewId: number }) =>
      api.applications.interview.cancelInterview({
        urlParams: {
          interviewId,
        },
        data: { status: "cancelled" },
      }),
    onSuccess: () => {
      refetchApplication()
      toggleInterviewDialog()
    },
    onError: err => {
      if (err instanceof APIError) {
        mapErrors(err)
      }
    },
  })

  const updateApplication = useMutation({
    mutationFn: ({
      id,
      eligibleForInterview,
    }: {
      id: number
      eligibleForInterview: boolean
    }) =>
      api.applications.updateApplication({
        urlParams: { applicationId: id },
        data: { eligibleForInterview },
      }),
    onSuccess: () => {
      refetchApplication()
      toggleInterviewDialog()
    },
    onError: err => {
      if (err instanceof APIError) {
        mapErrors(err)
      }
    },
  })

  const toggleAcceptApplicantDialog = () => {
    setAcceptApplicant(!acceptApplicant)
  }
  const toggleRejectApplicantDialog = () => {
    setRejectApplicant(!rejectApplicant)
  }
  const toggleInterviewDialog = () => {
    setInterviewDialog(!interviewDialog)
  }

  if (!applicantDetails)
    return (
      <div className="border-b border-onSurface-200  bg-white p-3">
        <BackButton />
      </div>
    )

  const tagDetails = getTagDetails({
    applicationStatus: applicantDetails.status,
    reviewStatus: applicantDetails.review?.status,
    interviewStatus: applicantDetails.interview?.status,
    eligibleForInterview: applicantDetails.eligibleForInterview,
  })

  const actionSlot = () => {
    if (
      !canTakeActions ||
      applicantDetails.status === ApplicationStatus.ABANDONED
    )
      return null
    const interviewButton = !applicantDetails.eligibleForInterview ? (
      <Button
        size="sm"
        variant="outlined"
        disabled={updateReviewStatus.isLoading}
        onClick={toggleInterviewDialog}
      >
        Schedule Interview
      </Button>
    ) : (
      <Button
        onClick={toggleInterviewDialog}
        size="sm"
        disabled={updateReviewStatus.isLoading}
        variant="outlined"
      >
        Cancel Interview
      </Button>
    )

    const isInterviewDone =
      applicantDetails.review?.status === ReviewStatus.ACCEPTED &&
      applicantDetails.eligibleForInterview &&
      applicantDetails.interview &&
      [
        InterviewStatus.ACCEPTED,
        InterviewStatus.REJECTED,
        InterviewStatus.NO_SHOW,
      ].includes(applicantDetails.interview.status)

    if (applicantDetails.status === ApplicationStatus.ACCEPTED) {
      return null
    }
    if (applicantDetails.review?.status === ReviewStatus.ACCEPTED) {
      return (
        <>
          {canRevertApplicationStatus && (
            <Button
              variant="text"
              loading={updateReviewStatus.isLoading}
              onClick={() => {
                updateReviewStatus.mutate({
                  reviewId: applicantDetails.review!.id,
                  status: "revert_review",
                })
              }}
            >
              Revert to In Review
            </Button>
          )}
          {interviewButton}
        </>
      )
    }

    if (applicantDetails.status === ApplicationStatus.REJECTED) {
      return (
        <>
          {canRevertApplicationStatus && (
            <Button
              variant="text"
              loading={updateReviewStatus.isLoading}
              onClick={() => {
                updateReviewStatus.mutate({
                  reviewId: applicantDetails.review!.id,
                  status: "revert_review",
                })
              }}
            >
              Revert to In Review
            </Button>
          )}
        </>
      )
    }

    return (
      <>
        <Button
          onClick={toggleRejectApplicantDialog}
          variant="text"
          color="critical"
        >
          Reject
        </Button>
        {!isInterviewDone && interviewButton}
        <Button onClick={toggleAcceptApplicantDialog} size="sm">
          Accept Application
        </Button>
      </>
    )
  }

  const getInterviewTag = () => {
    if (
      tagDetails === TagLabels.applicationAccepted ||
      tagDetails === TagLabels.admissionGranted ||
      tagDetails === TagLabels.rejected ||
      tagDetails === TagLabels.abandoned
    ) {
      return null
    }

    if (
      applicantDetails.eligibleForInterview &&
      (!applicantDetails.interview ||
        applicantDetails.interview?.status === InterviewStatus.CANCELLED)
    ) {
      return <ApplicationTag label={TagLabels.interviewPending} />
    }
    if (
      applicantDetails.eligibleForInterview &&
      applicantDetails.interview?.status === InterviewStatus.SCHEDULED
    ) {
      return <ApplicationTag label={TagLabels.interviewScheduled} />
    }

    if (
      applicantDetails.eligibleForInterview &&
      applicantDetails.interview &&
      [
        InterviewStatus.ACCEPTED,
        InterviewStatus.REJECTED,
        InterviewStatus.NO_SHOW,
      ].includes(applicantDetails.interview.status)
    ) {
      return <ApplicationTag label={TagLabels.interviewDone} />
    }

    return null
  }

  const getCancelChangesAction = () => {
    if (
      !canTakeActions ||
      applicantDetails.status === ApplicationStatus.ABANDONED ||
      applicantDetails.status === ApplicationStatus.REJECTED ||
      applicantDetails.status === ApplicationStatus.ACCEPTED
    )
      return null

    if (
      applicantDetails.review?.status === ReviewStatus.CHANGES_REQUESTED &&
      canTakeActions
    ) {
      return (
        <Button
          onClick={() => {
            cancelChanges.mutate({
              reviewId: applicantDetails.review!.id,
            })
          }}
          loading={cancelChanges.isLoading}
          variant="text"
          startAdornment={<Xmark />}
        >
          Cancel Changes
        </Button>
      )
    }
    return null
  }
  return (
    <>
      <div className="w-full overflow-auto border-b border-onSurface-200 bg-white">
        <div className="py-2">
          <PageContainer className="flex flex-wrap items-center justify-between gap-1">
            <div className="flex flex-col items-start gap-1 sm:flex-row">
              <BackButton className="-mt-0.5" />
              {/* <BackButton className="-mt-0.5" /> */}
              <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-1 sm:flex-row sm:items-center">
                  <Typography color="onSurface.800" variant="title3">
                    {name}
                  </Typography>

                  {crmIdentifiers && (
                    <Tooltip content={crmIdentifiers?.leadOwnerEmail}>
                      <Typography variant="body">
                        (Lead Owner - {crmIdentifiers.leadOwnerName})
                      </Typography>
                    </Tooltip>
                  )}
                  {reviewer && (
                    <Tooltip content={reviewer.email}>
                      <Typography variant="body">
                        (Reviewer - {reviewer.fullName})
                      </Typography>
                    </Tooltip>
                  )}
                </div>
                <div className="flex flex-wrap items-center gap-1">
                  {getInterviewTag()}
                  {tagDetails &&
                    tagDetails !== TagLabels.interviewDone &&
                    tagDetails !== TagLabels.interviewScheduled &&
                    tagDetails !== TagLabels.interviewPending && (
                      <ApplicationTag label={tagDetails} />
                    )}
                  {getCancelChangesAction()}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center gap-1.5">
              {actionSlot()}
            </div>
          </PageContainer>
        </div>
      </div>
      <ApplicationInterviewDialog
        name={name}
        open={interviewDialog}
        eligibility={applicantDetails.eligibleForInterview}
        onClose={toggleInterviewDialog}
        loading={cancelInterview.isLoading || updateApplication.isLoading}
        onClick={
          !applicantDetails.eligibleForInterview
            ? () => {
                updateApplication.mutate({
                  id: applicantDetails.id,
                  eligibleForInterview: true,
                })
              }
            : () => {
                if (
                  applicantDetails.eligibleForInterview &&
                  applicantDetails.interview?.status ===
                    InterviewStatus.SCHEDULED
                ) {
                  cancelInterview.mutate({
                    interviewId: applicantDetails.interview!.id,
                  })
                } else {
                  updateApplication.mutate({
                    id: applicantDetails.id,
                    eligibleForInterview: false,
                  })
                }
              }
        }
      />

      <AcceptApplicationDialog
        name={name}
        open={acceptApplicant}
        onClose={toggleAcceptApplicantDialog}
        loading={updateReviewStatus.isLoading}
        onClick={() => {
          updateReviewStatus.mutate({
            reviewId: applicantDetails.review!.id,
            status: ReviewStatus.ACCEPTED,
          })
        }}
      />
      <RejectApplicationDialog
        name={name}
        open={rejectApplicant}
        onClose={toggleRejectApplicantDialog}
        onClick={async reason => {
          await updateReviewStatus.mutateAsync({
            reviewId: applicantDetails.review!.id,
            status: ReviewStatus.REJECTED,
            reason: reason,
          })
        }}
      />
    </>
  )
}

export default TitleBar
