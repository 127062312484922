import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { NavArrowDown } from "iconoir-react"
import React, { useState } from "react"

import { TabNames } from "../constants"

export enum SortOptionsEnum {
  SubmitTimeAsc = "submit_time",
  SubmitTimeDesc = "-submit_time",
  FullNameAsc = "full_name",
  FullNameDesc = "-full_name",
}

const SortByOption: Record<
  TabNames,
  {
    category: string
    options: {
      label: string
      value: string
    }[]
  }[]
> = {
  [TabNames.AllApplications]: [
    {
      category: "Name",
      options: [
        { label: "A To Z", value: SortOptionsEnum.FullNameAsc },
        { label: "Z To A", value: SortOptionsEnum.FullNameDesc },
      ],
    },
    {
      category: "Date",
      options: [
        { label: "Newest First", value: SortOptionsEnum.SubmitTimeDesc },
        { label: "Oldest First", value: SortOptionsEnum.SubmitTimeAsc },
      ],
    },
  ],
  [TabNames.MyApplications]: [
    {
      category: "Name",
      options: [
        { label: "A To Z", value: SortOptionsEnum.FullNameAsc },
        { label: "Z To A", value: SortOptionsEnum.FullNameDesc },
      ],
    },
    {
      category: "Date",
      options: [
        { label: "Newest First", value: SortOptionsEnum.SubmitTimeDesc },
        { label: "Oldest First", value: SortOptionsEnum.SubmitTimeAsc },
      ],
    },
  ],
  [TabNames.Closed]: [
    {
      category: "Name",
      options: [
        { label: "A To Z", value: SortOptionsEnum.FullNameAsc },
        { label: "Z To A", value: SortOptionsEnum.FullNameDesc },
      ],
    },
    {
      category: "Date",
      options: [
        { label: "Newest First", value: SortOptionsEnum.SubmitTimeDesc },
        { label: "Oldest First", value: SortOptionsEnum.SubmitTimeAsc },
      ],
    },
  ],
}
const SortBy = ({
  sortBy,
  activeTabName,
  className,
  setSortBy,
}: {
  sortBy: string
  activeTabName: string
  className?: string
  setSortBy: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  const currentOption = SortByOption[activeTabName as TabNames].find(item =>
    item.options.some(i => i.value === sortBy)
  )
  const onSortBy = (state: string) => {
    setSortBy(state)
  }
  const onClear = () => {
    setSortBy("")
  }
  return (
    <DropdownMenu open={open} onOpenChange={toggleOpen}>
      <DropdownMenuTrigger asChild className={clsx("shrink-0", className)}>
        <Button
          color={sortBy ? "primary" : "black"}
          variant="text"
          endAdornment={
            <NavArrowDown className={sortBy ? "text-primary-500" : undefined} />
          }
        >
          {!sortBy && "Sort By"}
          {sortBy &&
            `By ${currentOption?.category} (${
              currentOption?.options.find(item => item.value === sortBy)?.label
            })`}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="py-1">
        <DropdownMenuLabel className="flex items-center justify-between text-title4">
          Sort By{" "}
          <Button color="critical" variant="text" onClick={onClear}>
            Clear
          </Button>
        </DropdownMenuLabel>
        <DropdownMenuRadioGroup
          value={sortBy}
          onValueChange={state => {
            onSortBy(state)
          }}
        >
          {SortByOption[activeTabName as TabNames].map(item => (
            <DropdownMenuGroup key={item.category}>
              <DropdownMenuLabel>{item.category}</DropdownMenuLabel>
              {item.options.map(option => (
                <DropdownMenuRadioItem
                  // className={clsx({
                  //   "bg-primary-50 text-primary-600": sortBy === option.value,
                  // })}
                  value={option.value}
                  key={option.label}
                >
                  {option.label}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuGroup>
          ))}{" "}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default SortBy
