import {
  Avatar,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { NavbarProps } from "components/shared/Navbar/types"
import { LogOut, NavArrowDown } from "iconoir-react"
import { createUseStyles } from "react-jss"
import { clearAuthInfo, getAuthInfo } from "utils/auth"
import { USER_TYPE } from "utils/constants"
// import { LogOut } from "iconoir-react"
import { getPlatformURL } from "utils/helpers"

import PageContainer from "../PageContainer"
import TruncatedText from "../TruncatedText"
import NavLogo from "./NavLogo"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.colors.common.white[500],
    maxHeight: "62px",
    height: "62px",
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },

  containerRoot: {
    width: "100%",
  },

  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
}))

const AdminNavbar = ({
  className,
  gutterBottom = true,
  slotEnd,
  slotStart,
}: NavbarProps) => {
  const classes = useStyles()

  const authInfo = getAuthInfo()
  const fullName = [authInfo?.user.firstName, authInfo?.user.lastName]
    .filter(Boolean)
    .join(" ")
  // const isLoggedIn = Boolean(authInfo)

  return (
    <>
      <div
        className={clsx({
          "mb-5": gutterBottom,
        })}
      >
        <nav
          className={clsx(classes.root, "flex items-center py-1", className)}
        >
          <PageContainer className={classes.containerRoot}>
            <div className={clsx(classes.container)}>
              <NavLogo href={"/"} slotStart={slotStart} />

              {/* End */}
              <div className="flex items-center gap-1">{slotEnd}</div>
              {authInfo && (
                <DropdownMenu>
                  <DropdownMenuTrigger
                    asChild
                    className={clsx("shrink-0", className)}
                  >
                    <Button variant="text" className="text-onSurface-900">
                      <span className="flex items-center gap-1">
                        <Avatar name={fullName} className="size-5" />
                        <div className="hidden text-start capitalize md:block">
                          <TruncatedText maxLength={30} variant="strong">
                            {fullName}
                          </TruncatedText>
                        </div>
                        <NavArrowDown className={clsx("hidden md:block")} />
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem
                      color="critical"
                      startAdornment={<LogOut />}
                      onClick={() => {
                        clearAuthInfo()
                        const url = new URL(getPlatformURL("sso", "logout"))
                        url.searchParams.append(
                          "origin",
                          `${window.location.origin}`
                        )
                        url.searchParams.append("platform", USER_TYPE)
                        window.location.href = url.href
                      }}
                    >
                      Log Out
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          </PageContainer>
        </nav>
      </div>
    </>
  )
}

export default AdminNavbar
