import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { FilterAlt } from "iconoir-react"
import { useState } from "react"

import { TabNames } from "../constants"
import ApplicationTag, { CustomTagsColors, TagLabels } from "./ApplicationTag"

export enum FilterOptionsEnum {
  ReviewPending = "review_pending",
  InReview = "in_review",
  ChangesRequested = "changes_requested",
  ChangesDone = "changes_done",
  Accepted = "accepted",
  InterviewPending = "interview_pending",
  InterviewScheduled = "interview_scheduled",
  InterviewDone = "interview_done",
  AdmissionGranted = "admission_granted",
  Rejected = "rejected",
  Abandoned = "abandoned",
  OnlyUploaded = "only_uploaded",
  OnlyNotUploaded = "only_not_uploaded",
}

const FilterOptions: Record<
  TabNames,
  {
    category: string
    options: {
      label: TagLabels | string
      value: string
      tagType?: CustomTagsColors
    }[]
  }[]
> = {
  [TabNames.AllApplications]: [
    {
      category: "Status",
      options: [
        {
          label: TagLabels.reviewPending,
          value: FilterOptionsEnum.ReviewPending,
          tagType: "warning",
        },
        {
          label: TagLabels.inReview,
          value: FilterOptionsEnum.InReview,
          tagType: "inverted_primary",
        },
        {
          label: TagLabels.changesRequested,
          value: FilterOptionsEnum.ChangesRequested,
          tagType: "onSurface",
        },
        {
          label: TagLabels.changesDone,
          value: FilterOptionsEnum.ChangesDone,
          tagType: "warning",
        },
        {
          label: TagLabels.interviewPending,
          value: FilterOptionsEnum.InterviewPending,
          tagType: "onSurface",
        },
        {
          label: TagLabels.interviewScheduled,
          value: FilterOptionsEnum.InterviewScheduled,
          tagType: "primary",
        },
        {
          label: TagLabels.interviewDone,
          value: FilterOptionsEnum.InterviewDone,
          tagType: "warning",
        },
        {
          label: TagLabels.applicationAccepted,
          value: FilterOptionsEnum.Accepted,
          tagType: "success",
        },
      ],
    },
    {
      category: "Documents",
      options: [
        { label: "Uploaded", value: FilterOptionsEnum.OnlyUploaded },
        {
          label: "Not Uploaded",
          value: FilterOptionsEnum.OnlyNotUploaded,
        },
      ],
    },
  ],
  [TabNames.MyApplications]: [
    {
      category: "Status",
      options: [
        {
          label: TagLabels.reviewPending,
          value: FilterOptionsEnum.ReviewPending,
          tagType: "warning",
        },
        {
          label: TagLabels.inReview,
          value: FilterOptionsEnum.InReview,
          tagType: "inverted_primary",
        },
        {
          label: TagLabels.changesRequested,
          value: FilterOptionsEnum.ChangesRequested,
          tagType: "onSurface",
        },
        {
          label: TagLabels.changesDone,
          value: FilterOptionsEnum.ChangesDone,
          tagType: "warning",
        },
        {
          label: TagLabels.interviewPending,
          value: FilterOptionsEnum.InterviewPending,
          tagType: "onSurface",
        },
        {
          label: TagLabels.interviewScheduled,
          value: FilterOptionsEnum.InterviewScheduled,
          tagType: "primary",
        },
        {
          label: TagLabels.interviewDone,
          value: FilterOptionsEnum.InterviewDone,
          tagType: "warning",
        },
        {
          label: TagLabels.applicationAccepted,
          value: FilterOptionsEnum.Accepted,
          tagType: "success",
        },
      ],
    },
    {
      category: "Documents",
      options: [
        { label: "Uploaded", value: FilterOptionsEnum.OnlyUploaded },
        {
          label: "Not Uploaded",
          value: FilterOptionsEnum.OnlyNotUploaded,
        },
      ],
    },
  ],
  [TabNames.Closed]: [
    {
      category: "Status",
      options: [
        {
          label: TagLabels.admissionGranted,
          value: FilterOptionsEnum.AdmissionGranted,
          tagType: "inverted_success",
        },
        {
          label: TagLabels.rejected,
          value: FilterOptionsEnum.Rejected,
          tagType: "critical",
        },
        {
          label: TagLabels.abandoned,
          value: FilterOptionsEnum.Abandoned,
          tagType: "critical",
        },
      ],
    },
    {
      category: "Documents",
      options: [
        { label: "Uploaded", value: FilterOptionsEnum.OnlyUploaded },
        {
          label: "Not Uploaded",
          value: FilterOptionsEnum.OnlyNotUploaded,
        },
      ],
    },
  ],
}

const Filter = ({
  filter,
  activeTabName,
  className,
  setFilter,
}: {
  filter: string[]
  activeTabName: string
  className?: string
  setFilter: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const onFilter = (value: string) => {
    setFilter(prevFilterList =>
      prevFilterList.includes(value)
        ? prevFilterList.filter(filterListItem => filterListItem !== value)
        : [...prevFilterList, value]
    )
  }
  const onClear = () => {
    setFilter([])
  }

  return (
    <DropdownMenu open={open} onOpenChange={toggleOpen}>
      <DropdownMenuTrigger asChild className={clsx("shrink-0", className)}>
        <Button
          color={filter.length > 0 ? "primary" : "black"}
          variant="text"
          endAdornment={
            <FilterAlt
              className={filter.length > 0 ? "fill-primary-500" : undefined}
            />
          }
        >
          Filter by
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="py-1">
        <DropdownMenuLabel className="flex items-center justify-between text-title4">
          Filter By{" "}
          <Button variant="text" color="critical" onClick={onClear}>
            Clear
          </Button>
        </DropdownMenuLabel>
        {FilterOptions[activeTabName as TabNames].map(item => (
          <DropdownMenuGroup key={item.category}>
            <DropdownMenuLabel>{item.category}</DropdownMenuLabel>
            {item.options.map(option => (
              <DropdownMenuCheckboxItem
                className={clsx("!py-0.5", {
                  "bg-primary-50 text-primary-600": filter.includes(
                    option.value
                  ),
                })}
                checked={filter.includes(option.value)}
                onClick={() => {
                  onFilter(option.value)
                }}
                key={option.label}
              >
                {option.value === FilterOptionsEnum.OnlyNotUploaded ||
                option.value === FilterOptionsEnum.OnlyUploaded ? (
                  <ApplicationTag
                    label={option.label}
                    className="bg-transparent text-onSurface-900"
                  />
                ) : (
                  <ApplicationTag label={option.label} />
                )}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuGroup>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default Filter
