import {
  Button,
  CircularProgress,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import clsx from "clsx"
import { FilterAlt } from "iconoir-react"
import { useState } from "react"
import { getAuthInfo } from "utils/auth"

const AssignedTo = ({
  filter,
  className,
  setFilter,
}: {
  filter: string[]
  className?: string
  setFilter: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const authInfo = getAuthInfo()

  // const [search, setSearch] = useState<string>("")
  // const debouncedSearchValue = useDebounce(search)
  const onFilter = (value: string) => {
    setFilter(prevFilterList =>
      prevFilterList.includes(value)
        ? prevFilterList.filter(filterListItem => filterListItem !== value)
        : [...prevFilterList, value]
    )
  }
  const { data: reviewersList, isLoading } = useQuery({
    queryKey: queries.application.reviewersList().queryKey,
    queryFn: () => api.applications.review.reviewersList(),
  })
  const onClear = () => {
    // setSearch("")
    setFilter([])
  }

  return (
    <DropdownMenu open={open} onOpenChange={toggleOpen}>
      <DropdownMenuTrigger asChild className={clsx("shrink-0", className)}>
        <Button
          variant="text"
          color={filter.length > 0 ? "primary" : "black"}
          endAdornment={
            <FilterAlt
              className={filter.length > 0 ? "fill-primary-500" : undefined}
            />
          }
        >
          Assigned To
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[400px] max-w-[350px] overflow-y-scroll bg-white">
        <DropdownMenuLabel className="flex items-center justify-between text-title4">
          Assigned To{" "}
          <Button variant="text" color="critical" onClick={onClear}>
            Clear
          </Button>
        </DropdownMenuLabel>
        {/* <DropdownMenuLabel className="gap-1">
         <TextField
            className="[&>div>input]:min-h-[auto] [&>div>input]:px-1.5 [&>div>input]:py-1 [&>div]:overflow-hidden [&>div]:rounded-full"
            value={search}
            onChange={(e: {
              target: { value: React.SetStateAction<string> }
            }) => {
              setSearch(e.target.value)
            }}
            onKeyDown={(event: { stopPropagation: () => void }) => {
              event.stopPropagation()
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            placeholder="Enter Name"
          />
          <Button variant="text" onClick={onClear} color="critical">
            Clear
          </Button>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />*/}
        {(!reviewersList || reviewersList?.length === 0) && (
          <DropdownMenuLabel>
            <Typography variant="title4" className="my-1">
              There are no records
            </Typography>
          </DropdownMenuLabel>
        )}
        {reviewersList && reviewersList.length > 0 && (
          <DropdownMenuCheckboxItem
            onClick={() => {
              onFilter("not_assigned")
            }}
            checked={filter.some(x => x === "not_assigned")}
          >
            Not Assigned (-)
          </DropdownMenuCheckboxItem>
        )}
        {reviewersList &&
          reviewersList.length > 0 &&
          reviewersList
            .sort(item => (item.uuid === authInfo.user.uuid ? -1 : 1))
            .map(reviewers => (
              <DropdownMenuCheckboxItem
                key={reviewers.uuid}
                onClick={() => {
                  onFilter(reviewers.uuid)
                }}
                checked={filter.some(x => x === reviewers.uuid)}
              >
                {reviewers.fullName}{" "}
                {reviewers.uuid === authInfo.user.uuid && `(Me)`}
              </DropdownMenuCheckboxItem>
            ))}
        {isLoading && (
          <div className="flex w-full items-center justify-center py-2">
            <CircularProgress size="xs" />
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default AssignedTo
