import {
  Button,
  IconButton,
  InputLabel,
  Switch,
  TextArea,
} from "@suraasa/placebo-ui"
import { ApplicationDeclaration } from "api/resources/applications/types"
import { Xmark } from "iconoir-react"
import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import { ApplicationContext } from "views/application/context/ApplicationContext"

const MAX_LENGTH = 2000

const DeclarationForm = ({ id }: { id: number }) => {
  const {
    applicationId,
    isReviewDisabled,
    setLocalDeclarations,
    localDeclarations,
  } = useContext(ApplicationContext)
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    setError,
  } = useForm<ApplicationDeclaration & { toggleRequiredDocuments: boolean }>()

  const declaration = watch("declaration")
  const toggleRequiredDocuments = watch("toggleRequiredDocuments")

  const onSubmit = handleSubmit(async data => {
    let hasError = false
    if (data.toggleRequiredDocuments && !data.requiredDocuments) {
      hasError = true
      setError("requiredDocuments", { message: "Required" })
    }

    if (hasError) return

    setLocalDeclarations(prev =>
      prev.map(item =>
        item.id === id
          ? {
              ...item,
              declaration: data.declaration,
              ...(data.requiredDocuments && {
                requiredDocuments: data.requiredDocuments,
              }),
            }
          : item
      )
    )
  })

  const onDeleteDeclaration = () => {
    setLocalDeclarations(prev => prev.filter(i => i.id !== id))
  }

  return (
    <form
      onSubmit={onSubmit}
      className="relative rounded-2xl border border-onSurface-300 p-2"
    >
      {!isReviewDisabled && (
        <IconButton
          variant="plain"
          color="black"
          className="absolute right-1 top-1 z-[1]"
          onClick={() => {
            onDeleteDeclaration()
          }}
        >
          <Xmark />
        </IconButton>
      )}
      <input
        type="number"
        className="hidden"
        value={id}
        {...register("id", {
          required: { value: true, message: "Required" },
        })}
      />
      <div className="mb-2 [&_p]:text-end">
        <TextArea
          label="Declaration"
          placeholder="Write declaration here..."
          errors={errors.declaration?.message}
          maxLength={MAX_LENGTH}
          helperText={`${
            declaration !== undefined ? declaration.length : 0
          }/${MAX_LENGTH}`}
          required
          {...register("declaration", {
            required: { value: true, message: "Required" },
            maxLength: MAX_LENGTH,
          })}
        />
      </div>
      <div className="flex flex-wrap gap-1">
        <InputLabel label="Ask users for related supporting documents" />
        <Switch
          size="xs"
          onClick={() => {
            if (toggleRequiredDocuments) {
              setValue("toggleRequiredDocuments", false)
            } else {
              setValue("toggleRequiredDocuments", true)
            }
          }}
        />
      </div>
      {toggleRequiredDocuments && (
        <TextArea
          className="mb-1"
          errors={errors.requiredDocuments?.message}
          placeholder="Mention the related documents for eg: Aadhar, Marriage Certificate"
          {...register("requiredDocuments")}
        />
      )}
      <Button className="ml-auto block" size="sm" rounded type="submit">
        Add Declaration
      </Button>
    </form>
  )
}

export default DeclarationForm
