import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSirius = getServiceURL(ServicePrefix.sirius)

export const urls = validateUrls({
  applications: {
    list: () => `/v1/applications/`,
    retrieve: applicationId => `/v1/applications/${applicationId}/`,
    retrieveProfile: applicationId =>
      getSirius(`/v1/profiles/${applicationId}/`),
    updateApplication: applicationId =>
      getSirius(`/v1/applications/${applicationId}/`),
    bulkCreateDeclaration: applicationId =>
      getSirius(`/v1/applications/${applicationId}/declarations/`),
  },
  review: {
    bulkCreate: reviewId => getSirius(`/v1/reviews/${reviewId}/changes/`),
    list: () => getSirius("/v1/reviews/changes/"),
    create: applicationId =>
      getSirius(`/v1/reviews/applications/${applicationId}/`),
    update: () => getSirius(`/v1/reviews/`),
    reviewersList: () => `/v1/applications/reviewers/`,
    cancelChanges: reviewId => getSirius(`/v1/reviews/${reviewId}/changes/`),
  },
  interview: {
    update: () => getSirius(`/v1/reviews/`),
    cancelInterview: interviewId =>
      getSirius(`/v1/reviews/interviews/${interviewId}/`),
  },
  overview: { list: () => getSirius(`/v1/applications/overview/admin/`) },
})
