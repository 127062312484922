import { useEffect } from "react"

const SentryTest = () => {
  useEffect(() => {
    const myNumber = 1
    // @ts-expect-error This is intentional.
    myNumber.split()
  }, [])

  return <div>SentryTest</div>
}

export default SentryTest
