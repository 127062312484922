import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TextArea,
  Typography,
} from "@suraasa/placebo-ui"
import { useForm } from "react-hook-form"

const RejectApplicationDialog = ({
  open,
  onClose: onDialogClose,
  name,
  onClick,
}: {
  open: boolean
  onClose: () => void
  name: string
  onClick: (reason: string) => Promise<void>
}) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ reason: string }>()

  const onSubmit = handleSubmit(async data => {
    await onClick(data.reason)
    reset()
  })

  const onClose = () => {
    reset()
    onDialogClose()
  }
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="bg-white" aria-describedby="dialog-description">
        <DialogHeader>
          <DialogTitle>Reject Application</DialogTitle>
        </DialogHeader>
        <DialogBody className="rounded-3xl">
          <form id="form" onSubmit={onSubmit}>
            <Typography className="mb-2">
              Are you sure you want to reject <strong>{name}’s</strong>{" "}
              application? If so please provide the reason:
            </Typography>
            <TextArea
              errors={errors.reason?.message}
              className="mb-1.5"
              placeholder="Type your reason here"
              rows={3}
              {...register("reason", {
                required: { value: true, message: "Required" },
              })}
            />
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            size="sm"
            color="critical"
            loading={isSubmitting}
            type="submit"
            form="form"
          >
            Reject
          </Button>
          <Button size="sm" variant="outlined" color="null" onClick={onClose}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default RejectApplicationDialog
