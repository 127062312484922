import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  ApplicantDetails,
  Certification,
  LanguageProficiency,
  PersonalDetails,
  Qualification,
  Reference,
  WorkExperience,
} from "api/resources/applications/types"
import { Country, LanguageProficiencyChoices } from "api/resources/global/types"
import { createContext, useEffect, useMemo, useState } from "react"
import useResources from "utils/hooks/useResources"

export type MedApplicationContextType = {
  applicationId: string
  applicantData: PersonalDetails
  questionList: ApplicantDetails["questionnaireQuestions"]
  languageProficiencies: LanguageProficiency[]
  qualifications: Qualification[]
  certifications: Certification[]
  workExperiences: WorkExperience[]
  references: Reference[]
  countries: Country[]
  toggleLoading: (state: boolean) => void
  isSalesPerson?: boolean
  submitTime: string | null | undefined
  applicantDetails: ApplicantDetails | undefined
  refetchApplication: () => void
  isFetching?: boolean
}

export const proficiencyChoices = [
  {
    label: "Elementary",
    value: LanguageProficiencyChoices.ELEMENTARY,
  },
  {
    label: "Limited Working",
    value: LanguageProficiencyChoices.LIMITED_WORKING,
  },
  {
    label: "Professional Working",
    value: LanguageProficiencyChoices.PROFESSIONAL_WORKING,
  },
  {
    label: "Full Professional",
    value: LanguageProficiencyChoices.FULL_PROFESSIONAL,
  },
  {
    label: "Native/Bi-lingual",
    value: LanguageProficiencyChoices.NATIVE,
  },
]

export const MedApplicationContext = createContext<MedApplicationContextType>({
  applicationId: "",
  applicantData: {
    id: 0,
    title: null,
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: null,
    gender: undefined,
    nationalIdNumber: null,
    nationalityId: null,
    mobilePhoneNumber: null,
    photo: undefined,
    passportNumber: null,
    whatsappPhoneNumber: null,
    homePhoneNumber: null,
    workPhoneNumber: null,
    currentResidence: undefined,
    permanentResidence: undefined,
    permanentSameAsCurrent: 0,
    addresses: [],
  },
  questionList: [],
  languageProficiencies: [],
  qualifications: [],
  certifications: [],
  workExperiences: [],
  references: [],
  countries: [],
  toggleLoading: () => {},
  isSalesPerson: false,
  submitTime: null,
  applicantDetails: undefined,
  refetchApplication: () => {},
  isFetching: undefined,
})

export const MedApplicationContextProvider = ({
  toggleLoading,
  applicationId,
  children,
  isSalesPerson,
}: {
  toggleLoading: (state: boolean) => void
  applicationId: string
  isSalesPerson?: boolean
  children: React.ReactNode
}) => {
  const [submitTime, setSubmitTime] = useState<string | undefined | null>(null)
  const [applicantData, setApplicantData] = useState<PersonalDetails>({
    id: 0,
    title: null,
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: null,
    gender: undefined,
    nationalIdNumber: null,
    nationalityId: null,
    mobilePhoneNumber: null,
    photo: undefined,
    passportNumber: null,
    whatsappPhoneNumber: null,
    homePhoneNumber: null,
    workPhoneNumber: null,
    currentResidence: undefined,
    permanentResidence: undefined,
    permanentSameAsCurrent: 0,
    addresses: [],
  })

  const { countries } = useResources(["countries"])

  const setPersonalDetailsData = (data: ApplicantDetails) => {
    setApplicantData({
      ...data.applicationDetail,
    })
    setSubmitTime(data.submitTime)
  }

  const application = useQuery({
    queryKey: queries.application.retrieve(applicationId).queryKey,
    queryFn: () =>
      api.applications.retrieve({
        urlParams: { applicationId },
        params: {
          fields: [
            "id",
            "questionnaire_questions",
            "application_detail.id",
            "application_detail.photo",
            "application_detail.title",
            "application_detail.first_name",
            "application_detail.last_name",
            "application_detail.date_of_birth",
            "application_detail.gender",
            "application_detail.nationality_id",
            "application_detail.email",
            "submit_time",
            "product_slug",
            "crm_identifiers",
          ],
        },
      }),
    enabled: Boolean(applicationId),
  })
  const questionList = useMemo(
    () => application.data?.questionnaireQuestions ?? [],
    [application.data?.questionnaireQuestions]
  )

  const references = useMemo(
    () => application.data?.references ?? [],
    [application.data?.references]
  )

  useEffect(() => {
    if (application.isSuccess) {
      setPersonalDetailsData(application.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application.isSuccess])

  const profileDetails = useQuery({
    queryKey: queries.application.retrieveApplication(applicationId).queryKey,
    queryFn: () =>
      api.applications.retrieveProfile({
        urlParams: { applicationId },
        params: {
          fields: [
            "language_proficiencies",
            "qualifications",
            "certifications",
            "work_experiences",
          ],
        },
      }),
    enabled: Boolean(applicationId),
  })
  const languageProficiencies = useMemo(
    () => profileDetails.data?.languageProficiencies ?? [],
    [profileDetails.data?.languageProficiencies]
  )
  const qualifications = useMemo(
    () => profileDetails.data?.qualifications ?? [],
    [profileDetails.data?.qualifications]
  )
  const certifications = useMemo(
    () => profileDetails.data?.certifications ?? [],
    [profileDetails.data?.certifications]
  )
  const workExperiences = useMemo(
    () => profileDetails.data?.workExperiences ?? [],
    [profileDetails.data?.workExperiences]
  )

  const contextValues = useMemo(
    () => ({
      applicationId,
      applicantData,
      questionList,
      languageProficiencies,
      qualifications,
      certifications,
      workExperiences,
      references,
      countries,
      toggleLoading,
      isSalesPerson,
      submitTime,

      applicantDetails: application.data,
      isLoading: application.isLoading,
      isFetching: application.isFetching,
      refetchApplication: () => {
        application.refetch()
      },
    }),
    [
      applicationId,
      applicantData,
      questionList,
      languageProficiencies,
      qualifications,
      certifications,
      workExperiences,
      references,
      countries,
      toggleLoading,
      isSalesPerson,
      submitTime,
      application,
    ]
  )
  return (
    <MedApplicationContext.Provider value={contextValues}>
      {children}
    </MedApplicationContext.Provider>
  )
}
