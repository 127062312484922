import { Typography } from "@suraasa/placebo-ui"
import Card from "components/shared/Card"
import { useContext } from "react"
import { SLUGS } from "utils/applicationForm"
import { ApplicationContext } from "views/application/context/ApplicationContext"

import Question from "./Question"

const OthersCard = () => {
  const { questionList } = useContext(ApplicationContext)

  const dynamicQuestions = questionList.filter(
    ({ question: { category, slug } }) =>
      category === SLUGS.step8.slug ||
      slug === "what-is-your-primary-motivation-to-pursue-this-pro-step1"
  )

  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Other Information</Typography>
      <div className="flex flex-col gap-4">
        {dynamicQuestions.map(({ question }) => {
          const selectedOption = question.options.find(val =>
            question.responses.some(resVal => resVal.option === val.id)
          )?.text
          const additionalAnswer = question.responses.find(val =>
            question.options.some(resVal => resVal.id === val.option)
          )?.additionalAnswer

          return (
            <div key={question.id}>
              <div>
                <div className="flex flex-col justify-between gap-1 sm:flex-row">
                  <Question data={question} hideResponse />
                  <Typography
                    color="primary.500"
                    variant="strong"
                    className="whitespace-pre"
                  >
                    {[selectedOption, additionalAnswer]
                      .filter(Boolean)
                      .join(": ")}
                  </Typography>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Card>
  )
}

export default OthersCard
