import CounsellorLogo from "assets/logos/counsellor/logo.webp"
import clsx from "clsx"
import React from "react"
type Props = {
  className?: string
  href?: string
  /**
   * Replaces Suraasa full logo with Suraasa mini logo
   * and appends slotStart children to the right of the updated logo
   */
  slotStart?: React.ReactElement | null
}

const NavLogo = ({ className, href = "#", slotStart }: Props) => (
  <div className={clsx("flex items-center gap-1", className)}>
    <a href={href}>
      <img src={CounsellorLogo} alt="counsellor-logo" className="size-5" />
    </a>
    {slotStart && slotStart}
  </div>
)

export default NavLogo
