import { Container } from "@suraasa/placebo-ui"
import clsx from "clsx"
import BackButton from "components/shared/BackButton"
import { useContext } from "react"
import { createUseStyles } from "react-jss"
import { MedApplicationContext } from "views/application/masters-in-education/context/MEdContext"

import { getApplicationTag } from "./utils"

const useStyles = createUseStyles(theme => ({
  titleBar: {
    width: "100%",
    background: "white",
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    overflowX: "auto",
  },
  bottomPadding: {
    paddingBottom: theme.spacing(7),
  },
  warning: {
    background: theme.colors.warning[50],
    borderTop: `1px solid ${theme.colors.warning[200]}`,
    borderBottom: `1px solid ${theme.colors.warning[200]}`,
  },
  critical: {
    background: theme.colors.critical[50],
    borderTop: `1px solid ${theme.colors.critical[200]}`,
    borderBottom: `1px solid ${theme.colors.critical[200]}`,
  },
  onSurface: {
    background: theme.colors.onSurface[50],
    borderTop: `1px solid ${theme.colors.onSurface[200]}`,
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },
  success: {
    background: theme.colors.success[50],
    borderTop: `1px solid ${theme.colors.success[200]}`,
    borderBottom: `1px solid ${theme.colors.success[200]}`,
  },
  chipWarning: {
    background: `${theme.colors.warning[50]} !important`,
    border: `1px solid ${theme.colors.warning[400]} !important`,
  },
  chipOnSurface: {
    background: `${theme.colors.onSurface[50]} !important`,
    border: `1px solid ${theme.colors.onSurface[400]} !important`,
  },
  chipSuccess: {
    background: `${theme.colors.success[50]} !important`,
    border: `1px solid ${theme.colors.success[400]} !important`,
  },
  chipCritical: {
    background: `${theme.colors.critical[50]} !important`,
    border: `1px solid ${theme.colors.critical[400]} !important`,
  },
}))

const TitleBar = () => {
  const classes = useStyles()
  const { applicantDetails } = useContext(MedApplicationContext)

  const tag = applicantDetails
    ? getApplicationTag({
        reviewStatus: applicantDetails.review?.status,
        interviewStatus: applicantDetails.interview?.status,
      })
    : null

  return (
    <>
      <div
        className={clsx(classes.titleBar, {
          [classes.warning]: tag?.color === "warning",
          [classes.onSurface]: tag?.color === "onSurface",
          [classes.success]: tag?.color === "success",
          [classes.critical]: tag?.color === "critical",
        })}
      >
        <div className="py-2">
          <Container>
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-center gap-2">
                <BackButton />
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div />
    </>
  )
}

export default TitleBar
