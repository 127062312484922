import { Button, CircularProgress, Typography } from "@suraasa/placebo-ui"
import MysteryBox from "assets/Fallback/mystery-box.svg"
import clsx from "clsx"
import { InfoCircle, WarningTriangle } from "iconoir-react"
import React from "react"

type Props = {
  emptyStateDetails?: {
    image?: string | null
    title: string
    description?: string
  }
  isSuccess: boolean
  isEmpty?: boolean
  isLoading?: boolean
  isFetching?: boolean
  isError?: boolean
  children?: React.ReactNode
  className?: string
  minHeight?: number
}

const defaultEmptyStateDetails = {
  image: MysteryBox,
  title: "There are no records available.",
}

const UIStateLayout = ({
  emptyStateDetails,
  isSuccess,
  isLoading,
  isEmpty,
  isError,
  children,
  className,
  isFetching,
  minHeight = 400,
}: Props) => {
  if (isError && isFetching) {
    return (
      <div className="flex items-center justify-center" style={{ minHeight }}>
        <CircularProgress />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center" style={{ minHeight }}>
        <CircularProgress />
      </div>
    )
  }

  emptyStateDetails = {
    ...defaultEmptyStateDetails,
    ...emptyStateDetails,
    image: emptyStateDetails?.image,
  }

  if (isError) {
    return (
      <div
        className={clsx(
          "flex flex-col items-center justify-center gap-2",
          className
        )}
        style={{ minHeight }}
      >
        <div className="grid size-10 place-items-center rounded-full bg-critical-100">
          <InfoCircle className="size-5 text-critical-500" />
        </div>
        <Typography variant="title3" className="text-center">
          It seems that there has been some issue! <br />
          Refresh or try again later.
        </Typography>
        <Button
          size="xs"
          color="critical"
          onClick={() => {
            window.location.reload()
          }}
        >
          Refresh the page
        </Button>
      </div>
    )
  }

  if (isEmpty) {
    return (
      <>
        <div
          className={clsx(
            "flex flex-col items-center justify-center gap-2",
            className
          )}
          style={{ minHeight }}
        >
          {emptyStateDetails?.image ? (
            <img
              src={emptyStateDetails.image}
              alt="svg-logo"
              className="mx-auto mb-3"
            />
          ) : (
            <div className="grid size-10 place-items-center rounded-full bg-warning-100">
              <WarningTriangle className="size-5 text-warning-500" />
            </div>
          )}
          <Typography
            variant="title3"
            className="mb-1 text-center text-onSurface-800"
          >
            {emptyStateDetails.title}
          </Typography>
          {emptyStateDetails.description && (
            <Typography
              variant="smallBody"
              className="text-center text-onSurface-500"
            >
              {emptyStateDetails.description}
            </Typography>
          )}
        </div>

        {children}
      </>
    )
  }

  if (isSuccess && isFetching) {
    return (
      <div className="relative">
        {children}
        <div
          className={clsx(
            "absolute left-0 top-0 flex size-full items-center justify-center bg-white/60 py-2",
            className
          )}
        >
          <CircularProgress />
        </div>
      </div>
    )
  }

  if (!children) {
    return null
  }

  if (isSuccess) {
    return <>{children}</>
  }

  return null
}

export default UIStateLayout
