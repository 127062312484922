import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"

const ApplicationInterviewDialog = ({
  open,
  onClose,
  name,
  onClick,
  loading,
  eligibility,
}: {
  open: boolean
  onClose: () => void
  name: string
  onClick: () => void
  loading?: boolean
  eligibility: boolean
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="bg-white" aria-describedby="dialog-description">
        <DialogHeader>
          <DialogTitle>
            {eligibility ? `Cancel` : `Schedule`} Interview
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="rounded-3xl">
          {eligibility ? (
            <Typography className="pb-3">
              Do you want to cancel interview for <strong>{name}</strong>?
            </Typography>
          ) : (
            <Typography className="pb-3">
              Do you want to schedule interview for <strong>{name}</strong>?
            </Typography>
          )}
        </DialogBody>
        <DialogFooter>
          <Button loading={loading} size="sm" onClick={onClick}>
            {eligibility ? `Cancel` : `Schedule`} Interview
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ApplicationInterviewDialog
