import { Button, Typography, TypographyProps } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"
import { BaseComponentProps } from "types"

const useStyles = createUseStyles(theme => ({
  toggleTruncateButton: {
    marginLeft: theme.spacing(1),
  },
}))

type TruncatedTextProp = {
  maxLength: number
} & TypographyProps

const TruncatedText = ({
  maxLength,
  children,
  className,
  ...props
}: React.PropsWithChildren<BaseComponentProps<TruncatedTextProp>>) => {
  const [seeAll, setSeeAll] = React.useState(false)

  const classes = useStyles()

  return (
    <div className={className}>
      <Typography {...props} className={clsx("inline")}>
        {children &&
          (!seeAll && children.toString().length > maxLength
            ? `${children.toString().slice(0, maxLength)}...`
            : children.toString())}
      </Typography>

      {children && children.toString().length > maxLength && (
        <Button
          className={classes.toggleTruncateButton}
          variant="text"
          onClick={() => setSeeAll(!seeAll)}
        >
          {seeAll ? "Read Less" : "Read More"}
        </Button>
      )}
    </div>
  )
}

export default TruncatedText
