import { CircularProgress } from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
import { useMemo } from "react"
import { mapErrors } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import { FilterOptionsEnum } from "../Tools/Filter"
import UIStateLayout from "../Tools/UIStateLayout"
import ApplicationRow from "./ApplicationRow"

const Header = () => (
  <div className="hidden grid-cols-12 rounded-xl bg-onSurface-900 py-2.5 text-white md:grid">
    <span className="col-span-4 block pl-3 text-start text-sm font-semibold leading-[18px] tracking-[-0.02em]">
      Name
    </span>
    <span className="col-span-2 block text-start text-sm font-semibold leading-[18px] tracking-[-0.02em]">
      Date
    </span>
    <span className="col-span-2 block text-start text-sm font-semibold leading-[18px] tracking-[-0.02em]">
      Status
    </span>
    <span className="col-span-1 block text-start text-sm font-semibold leading-[18px] tracking-[-0.02em]">
      Documents
    </span>
    <span className="col-span-2 block text-start text-sm font-semibold leading-[18px] tracking-[-0.02em]">
      Assigned To
    </span>
    <span className="col-span-1 block text-start text-sm font-semibold leading-[18px] tracking-[-0.02em]" />
  </div>
)

const MyApplications = ({
  search,
  sortBy,
  filter,
  assignedTo,
}: {
  sortBy: string
  search: string
  filter: string[]
  assignedTo: string[]
}) => {
  const reviewStatus = filter.filter(
    i =>
      i !== FilterOptionsEnum.OnlyUploaded &&
      i !== FilterOptionsEnum.OnlyNotUploaded
  )

  const hasEvidenceUploaded = useMemo(() => {
    const hasEvidence = filter.includes(FilterOptionsEnum.OnlyUploaded)
    const hasNoEvidence = filter.includes(FilterOptionsEnum.OnlyNotUploaded)
    if (hasEvidence && hasNoEvidence) {
      return undefined
    }
    if (hasEvidence) {
      return true
    }
    if (hasNoEvidence) {
      return false
    }
    return undefined
  }, [filter])
  const {
    isSuccess,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isError,
    data,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [
      ...queries.application.applications().queryKey,
      filter,
      search,
      "my",
      sortBy,
    ],
    queryFn: x =>
      api.applications.applications({
        params: {
          status: "my",
          ...(sortBy && { ordering: sortBy }),
          ...(reviewStatus.length > 0 && { review_status: reviewStatus }),
          ...(search && { search }),
          ...(assignedTo.length > 0 && { assignedTo }),
          ...(hasEvidenceUploaded !== undefined && {
            evidence_uploaded: hasEvidenceUploaded,
          }),
          page: x.pageParam || 1,
        },
      }),
    onError: err => {
      if (err instanceof APIError) {
        mapErrors(err)
        console.error(err.statusCode)
        console.error(err.is500)
        console.error(err.__errors)
      }
    },

    getNextPageParam: lastPage => {
      return lastPage.nextPage === null ? undefined : lastPage.nextPage
    },
  })
  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })
  const allData = data?.pages.flatMap(page => page.data)

  return (
    <>
      <Header />
      <UIStateLayout
        isError={isError}
        isSuccess={isSuccess}
        isLoading={isLoading}
        isEmpty={allData?.length === 0}
        emptyStateDetails={{ title: "There are no records available." }}
      >
        <div className="mt-1 overflow-hidden rounded-xl border border-onSurface-200">
          {data?.pages.map((page, pageNumber) => {
            return page.data.map((item, index) => {
              const PAGE_SIZE = 10
              // Doing +1 because index starts from zero
              const currentIndex = pageNumber * PAGE_SIZE + (index + 1)

              // Doing -1 because page length should start from 0
              const totalLoadedItems =
                (data.pages.length - 1) * PAGE_SIZE + page.data.length

              const isLastItem = currentIndex === totalLoadedItems

              return (
                <div key={item.id} ref={isLastItem ? ref : null}>
                  <ApplicationRow data={item} />
                </div>
              )
            })
          })}
        </div>
        {isFetching && (
          <div className="grid place-items-center p-4">
            <CircularProgress />
          </div>
        )}
      </UIStateLayout>
    </>
  )
}

export default MyApplications
