import { Typography } from "@suraasa/placebo-ui"
import { LanguageProficiency } from "api/resources/applications/types"
import Card from "components/shared/Card"
import { useContext } from "react"
import {
  MedApplicationContext,
  proficiencyChoices,
} from "views/application/masters-in-education/context/MEdContext"

import AttachmentItem from "./Attachment"

const LanguageCard = () => {
  const { languageProficiencies } = useContext(MedApplicationContext)

  const getSelectedProficiency = (data: LanguageProficiency) => {
    const selected = proficiencyChoices.filter(
      a => a.value === data.proficiency
    )[0]
    return selected?.label ?? ""
  }
  return (
    <Card className="mt-4 p-2">
      <Typography variant="title3">Language Proficiency</Typography>
      <div className="flex flex-col gap-3">
        {languageProficiencies.map(item => (
          <div
            key={item.id}
            className="flex w-full flex-col justify-between gap-1 md:flex-row"
          >
            <div>
              <Typography variant="strong">{item.language.name}</Typography>
              <Typography color="onSurface.800" variant="body">
                {getSelectedProficiency(item)}
              </Typography>
            </div>
            <div>
              {item.hasEvidence && (
                <AttachmentItem
                  attachmentName={item.attachmentName}
                  link={item.attachment}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}

export default LanguageCard
